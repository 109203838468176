import {
    createContext,
    useContext,
    useState,
    useCallback
} from "react";
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

const ToastContext = createContext();

export function ToastProvider({
    children
}) {
    const [toasts, setToasts] = useState([]);

    const [counter, setCounter] = useState(1);

    const addToastCallback = useCallback(
        (header, body, options = {}) => {
            const id = counter;

            setCounter(id + 1);

            const toast = <ToastMessage
                id={id}
                header={header}
                body={body}
                doRemove={() => {
                    setToasts(toasts.filter(ti => ti.toastid !== id))
                }}
                variant={options.variant ?? "light"}
                delay={options.delay ?? 3000}
            />;

            setToasts(toasts.concat([toast]));
        },
        [toasts, counter]);

    return (
        <ToastContext.Provider value={{ addToast: addToastCallback }}>
            {children}
            <ToastContainer position="bottom-end" className="p-3">
                {toasts}
            </ToastContainer>
        </ToastContext.Provider>
    )
}

function ToastMessage(props) {
    const {
        id,
        header,
        body,
        variant,
        delay,
        doRemove = () => { }
    } = props;

    const [show, setShow] = useState(true);

    const variantClassNames={
        "primary": "text-white",
        "secondary": "text-white",
        "success": "text-white",
        "danger": "text-white",
        "warning": "",
        "info": "",
        "light": "",
        "dark": "text-white"
    };

    return <Toast
        show={show}
        toastid={id}
        key={`toast-${id}`}
        onClose={() => {
            setShow(false);
            doRemove();
        }}
        delay={delay}
        autohide
        bg={variant}
    >
        <Toast.Header closeButton={true}>
            { /* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
            <strong className="me-auto">{header}</strong>
            { /* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body
            className={variantClassNames[variant]}
        >
            {body}
        </Toast.Body>
    </Toast>;
}

export const useToast = () => useContext(ToastContext);