import React from "react";
import {
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    BsLockFill,
    BsPencilFill,
    BsTrashFill
} from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {
    useSecrets
} from "../secret/hooks.jsx";
import { deleteSecret } from "../secret/services.jsx";
import {
    BsPlus
} from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { useToast } from "../toast/ToastProvider";
import Placeholder from 'react-bootstrap/Placeholder';

function SecretsWorkspaceSettingsPane(props) {
    const [timestamp, setTimestamp] = useState(Date.now());

    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useSecrets(timestamp);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingSecret, setDeletingSecret] = useState(null);

    const { addToast } = useToast();

    return <>
        <h2>Secrets</h2>
        <hr />
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    <Row>
                        <Col xs={11}>
                            Workspace Secrets
                        </Col>
                        <Col xs={1}>
                            <Button
                                className="w-100"
                                as={Link}
                                variant="primary"
                                to={"new"}
                                size="sm"
                            >
                                <BsPlus size="14pt" />
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {
                        loading
                            ? [0, 1, 2].map(xi => <SecretRowPlaceholder />)
                            : data.items.map(si => {
                                return <SecretRow
                                    id={si.id}
                                    name={si.name}
                                    retrievedAt={si.retrievedAt}
                                    onDelete={() => {
                                        setDeletingSecret(si);
                                        setShowDeleteModal(true);
                                    }}
                                />;
                            })
                    }
                </Card.Text>
            </Card.Body>
        </Card>
        <DeleteWorkspaceSecretModal
            secret={deletingSecret}
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            onConfirm={e => {
                deleteSecret(deletingSecret.id)
                    .then(function () {
                        setTimestamp(Date.now());
                    })
                    .catch(function (e) {
                        if (e.response.status === 404) {
                            // That's fine. I don't care.
                        }
                        else {
                            let message;
                            switch (e.response.status) {
                                case 403:
                                    message = "You don't have permission to delete this secret.";
                                    break;
                                case 409:
                                    message = "Some tools still refer to the secret. To delete the secret, remove the referrals.";
                                    break;
                                default:
                                    message = "Something went wrong!";
                                    break;
                            }
                            addToast("Failed to delete secret", message, {
                                "variant": "danger"
                            });
                        }
                    });
            }}
        />
    </>;
}

function SecretRow(props) {
    const { name, retrievedAt, onDelete } = props;
    return <Row className="mb-1">
        <Col xs={9}>
            <BsLockFill />
            {" "}
            <Link to={name}>{name}</Link>
        </Col>
        <Col xs={3} style={{ textAlign: "right" }}>
            Last used {retrievedAt ? retrievedAt.slice(0, 10) : "never"}
            {" "}
            <Button
                as={Link}
                to={name}
                variant="secondary"
                size="sm"
            >
                <BsPencilFill />
            </Button>
            {" "}
            <Button
                onClick={onDelete}
                variant="secondary"
                size="sm"
            >
                <BsTrashFill />
            </Button>
        </Col>
    </Row>;
}


function SecretRowPlaceholder(props) {
    return <Row className="mb-1">
        <Col xs={9}>
            <BsLockFill />
            {" "}
            <Placeholder as="span" animation="wave">
                <Placeholder xs={6} size="lg" />
            </Placeholder>
        </Col>
        <Col xs={3} style={{ textAlign: "right" }}>
            <Placeholder as="span" animation="wave">
                <Placeholder xs={6} size="lg" />
            </Placeholder>
            {" "}
            <Button
                variant="secondary"
                size="sm"
                disabled={true}
            >
                <BsPencilFill />
            </Button>
            {" "}
            <Button
                variant="secondary"
                size="sm"
                disabled={true}
            >
                <BsTrashFill />
            </Button>
        </Col>
    </Row>;
}

function DeleteWorkspaceSecretModal(props) {
    const {
        secret,
        onConfirm,
        show,
        setShow
    } = props;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm();
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Delete Secret?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you delete this secret, then it will no longer be available for executions!
                </Alert>
                <p>
                    Are you sure you want to delete {secret?.name}?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                >
                    Yes, Delete this Secret.
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SecretsWorkspaceSettingsPane;