import { API_BASE_URL } from "../util";
import axios from "axios";

export function getAccount(reference) {
    let url = API_BASE_URL + "/accounts/" + encodeURIComponent(reference);
    return axios.get(url);
}

export function getAccountById(id) {
    let url = API_BASE_URL + "/accounts/by/id/" + encodeURIComponent(id);
    return axios.get(url);
}

export function getAccountByUsername(username) {
    let url = API_BASE_URL + "/accounts/by/username/" + encodeURIComponent(username);
    return axios.get(url);
}

export function getAccounts(query, order, cursor) {
    let url = API_BASE_URL + "/accounts?limit=100";
    if (query !== null && query !== undefined && query !== "")
        url = url + "&q=" + encodeURIComponent(query);
    if (order !== null && order !== undefined)
        url = url + "&order=" + encodeURIComponent(order);
    if (cursor !== null && cursor !== undefined)
        url = url + "&cursor=" + encodeURIComponent(cursor);
    return axios.get(url);
}