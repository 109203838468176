import React from "react";
import {
    useState
} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";
import ExecutionList from './ExecutionList';


function ExecutionsPage(props) {
    const [query, setQuery] = useState("");
    const [owner, setOwner] = useState("me");
    const [state, setState] = useState("any");
    const [loading, setLoading] = useState(true);
    return (
        <>
            <Row className="mb-3 mt-3">
                <Col xs={12} md={6}>
                    <Form.Control
                        type="text"
                        placeholder="Keyword Search Query"
                        disabled={loading}
                        onBlur={e => setQuery(e.target.value)}
                        onKeyUp={e => {
                            if(e.key === "Enter") {
                                e.target.blur();
                            }
                        }}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <Form.Select
                        disabled={loading}
                        onChange={e => setOwner(e.target.value)}
                    >
                        <option value="me">My Jobs Only</option>
                        <option value="team">All Team Jobs</option>
                    </Form.Select>
                </Col>
                <Col xs={12} md={3}>
                    <Form.Select
                        disabled={loading}
                        onChange={e => setState(e.target.value)}
                    >
                        <option value="any">In Any State</option>
                        <option value="starting">In Starting State</option>
                        <option value="running">In Running State</option>
                        <option value="succeeded">In Succeeded State</option>
                        <option value="failed">In Failed State</option>
                    </Form.Select>
                </Col>
            </Row>
            <ExecutionList
                query={query}
                owner={owner === "team" ? null : owner}
                state={state === "any" ? null : state}
                onLoading={(loading) => {
                    setLoading(loading);
                }}
            />
        </>
    );
}

export default ExecutionsPage;