import React from "react";
import {
    useState
} from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { createMyApiKey } from "./services.jsx";

function CreateApiKeyAccountSettingsPane(props) {
    const [description, setDescription] = useState("");
    const [creating, setCreating] = useState(false);

    const navigate = useNavigate();

    const populated = description !== "";

    return <>
        <h2><Link to="..">Keys</Link> / Create API Key</h2>
        <hr />
        <h3>Description</h3>
        <Form.Control
            className="mb-3"
            placeholder="API Key Description"
            disabled={creating}
            value={description}
            onChange={e => setDescription(e.target.value)}
        />
        <Button
            disabled={creating || !populated}
            onClick={e => {
                setCreating(true);
                createMyApiKey(description)
                    .then(response => {
                        setCreating(false);
                        console.log("navigate state");
                        console.log(response.data);
                        navigate("..", { state: { newApiKey: response.data } });
                    })
                    .catch(error => {
                        setCreating(false);
                        console.log(error);
                    })
            }}
        >
            Create API Key
        </Button>
    </>;
}

export default CreateApiKeyAccountSettingsPane;