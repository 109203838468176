import {
    API_BASE_URL
} from "../util";
import axios from "axios";

export function getMyApiKeys() {
    let url = `${API_BASE_URL}/accounts/me/apiKeys`;
    return axios.get(url);
}

export function createMyApiKey(description) {
    let url = `${API_BASE_URL}/accounts/me/apiKeys`;
    return axios.post(url, {description});
}

export function deleteMyApiKey(id) {
    let url = `${API_BASE_URL}/accounts/me/apiKeys/${id}`;
    return axios.delete(url);
}