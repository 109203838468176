import React from "react";
import {
    useState
} from "react";
import {
    useAccountByUsername
} from "./hooks";
import {
    useParams
} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExecutionList from '../execution/ExecutionList';

function TeamAccountPage(props) {
    const { username } = useParams();

    // eslint-disable-next-line no-unused-vars
    const [userLoading, userData, userError] = useAccountByUsername(username);

    const [executionQuery, setExecutionQuery] = useState("");
    const [executionState, setExecutionState] = useState("any");

    const [executionsLoading, setExecutionsLoading] = useState(true);

    return (
        <Row className="mb-3 mt-3">
            <Col xs={12} md={3}>
                <img
                    alt=""
                    src={userLoading ? "/images/avatar.png" : userData.picture}
                    style={{ width: "100%", maxWidth: "200px", display: "block", margin: "auto" }}
                />
                <h6
                    style={{ margin: "auto", textAlign: "center" }}
                >
                    {userLoading ? "Loading..." : userData.username}
                </h6>
            </Col>
            <Col xs={12} md={9}>
                <Tabs
                    defaultActiveKey="executions"
                    className="mb-3"
                >
                    <Tab eventKey="executions" title="Executions">
                        <Row className="mb-3 mt-3">
                            <Col xs={12} md={8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Keyword Search Query"
                                    disabled={userLoading || executionsLoading}
                                    onBlur={e => setExecutionQuery(e.target.value)}
                                    onKeyUp={e => {
                                        if(e.key === "Enter") {
                                            e.target.blur();
                                        }
                                    }}            
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Select
                                    disabled={userLoading || executionsLoading}
                                    onChange={e => setExecutionState(e.target.value)}
                                >
                                    <option value="any">In Any State</option>
                                    <option value="starting">In Starting State</option>
                                    <option value="running">In Running State</option>
                                    <option value="succeeded">In Succeeded State</option>
                                    <option value="failed">In Failed State</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        {
                            userLoading
                                ? <Spinner animation="border" />
                                : <ExecutionList
                                    query={executionQuery}
                                    owner={userData.id}
                                    state={executionState === "any" ? null : executionState}
                                    hideOwnerColumn={true}
                                    onLoading={(loading) => {
                                        setExecutionsLoading(loading);
                                    }}
                                />
                        }
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    );
}

export default TeamAccountPage;