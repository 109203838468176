import React from "react";
import {
    useState
} from "react";
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import {
    useSecretByName
} from "../secret/hooks.jsx";
import { updateSecret } from "../secret/services.jsx";

function SecretWorkspaceSettingsPane(props) {
    const { secretName } = useParams();

    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useSecretByName(secretName);
    const [newSecretValue, setNewSecretValue] = useState("");
    const [updating, setUpdating] = useState(false);

    const navigate = useNavigate();

    const populated = newSecretValue !== "";

    return <>
        <h2><Link to=".." relative="path">Secrets</Link> / Update secret</h2>
        <hr />
        <h3>{secretName}</h3>
        <p>Secret values are encrypted and cannot be displayed, but you can enter a new value below.</p>
        <Form.Control
            as="textarea"
            rows={3}
            placeholder="New secret value"
            disabled={loading || updating}
            value={newSecretValue}
            onChange={e => setNewSecretValue(e.target.value)}
        />
        <Button
            className="mt-3"
            disabled={loading || updating || !populated}
            onClick={e => {
                setUpdating(true);
                updateSecret(data.id, { "value": newSecretValue })
                    .then(response => {
                        setUpdating(false);
                        navigate("..", { relative: "path" });
                    })
                    .catch(error => {
                        setUpdating(false);
                        console.log(error);
                    })
            }}
        >
            Save Changes
        </Button>
    </>;
}

export default SecretWorkspaceSettingsPane;