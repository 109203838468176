import {
    useEffect,
    useState
} from "react";
import { getAccount, getAccounts, getAccountById, getAccountByUsername } from "./services";

export function useAccountsPage(query, order, cursor) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getAccounts(query, order, cursor)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [query, order, cursor]);

    return [loading, data, error];
}

export function useAccount(reference) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getAccount(reference)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [reference]);

    return [loading, data, error];
}

export function useAccountById(id) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getAccountById(id)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [id]);

    return [loading, data, error];
}

export function useAccountByUsername(username) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getAccountByUsername(username)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [username]);

    return [loading, data, error];
}