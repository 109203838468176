import React from "react";
import {
    useState
} from "react";
import {
    useAccountsPage
} from "./hooks";
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import Image from 'react-bootstrap/Image';
import {
    Link
} from "react-router-dom";
import { useEffect } from "react";

function TeamPage(props) {
    const [query, setQuery] = useState("");
    const [order, setOrder] = useState("activeAt");
    const [loading, setLoading] = useState(true);
    return (
        <>
            <Row className="mb-3 mt-3">
                <Col xs={12} md={9}>
                    <Form.Control
                        type="text"
                        placeholder="Prefix Search Query"
                        disabled={loading}
                        onBlur={e => setQuery(e.target.value)}
                        onKeyUp={e => {
                            if (e.key === "Enter") {
                                e.target.blur();
                            }
                        }}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <Form.Select
                        disabled={loading}
                        onChange={e => setOrder(e.target.value)}
                    >
                        <option value="activeAt">Sorted by Last Activity</option>
                        <option value="lastExecutionAt">Sorted by Most Recent Execution</option>
                        <option value="username">Sorted by Name</option>
                        <option value="createdAt">Sorted by Creation Date</option>
                    </Form.Select>
                </Col>
            </Row>
            <AccountList
                query={query}
                order={order}
                onLoading={(loading) => {
                    console.log(loading);
                    setLoading(loading);
                }}
            />
        </>
    );
}

function AccountList(props) {
    const { query, order, onLoading } = props;

    // eslint-disable-next-line no-unused-vars
    const [cursor, setCursor] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useAccountsPage(query, order, cursor);

    useEffect(function () {
        onLoading(loading);
    }, [loading]);

    return (
        <div className="accounts-container">
            <Container>
                <Row>
                    <Col xs={3}>
                        <h6>User</h6>
                    </Col>
                    <Col xs={3}>
                        <h6>Last Execution At</h6>
                    </Col>
                    <Col xs={3}>
                        <h6>Last Login At</h6>
                    </Col>
                    <Col xs={3}>
                        <h6>Created At</h6>
                    </Col>
                </Row>
                {loading
                    ? [1,2,3,4,5,6,7,8,9,10].map(i => <AccountPlaceholder />)
                    : data.items.map(ai => <AccountCard
                        key={ai.id}
                        id={ai.id}
                        username={ai.username}
                        lastExecutionAt={ai.lastExecutionAt}
                        activeAt={ai.activeAt}
                        createdAt={ai.createdAt}
                        picture={ai.picture}
                    />)
                }
            </Container>
        </div>
    );
}

function AccountPlaceholder(props) {
    const avatar = <Image
        src="/images/avatar.png"
        roundedCircle={true}
        fluid={true}
        style={{ width: "24px", height: "24px" }}
    />;

    return (
        <Row>
            <Col xs={3}>
                {avatar}
                {" "}
                <Placeholder as="span" animation="wave">
                    <Placeholder xs={8} />
                </Placeholder>
            </Col>
            <Col xs={3}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
            <Col xs={3}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
            <Col xs={3}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
        </Row>
    );
}

function AccountCard(props) {
    const {
        // eslint-disable-next-line no-unused-vars
        id,
        username,
        lastExecutionAt,
        activeAt,
        createdAt,
        picture
    } = props;

    const avatar = <Image
        src={picture}
        roundedCircle={true}
        fluid={true}
        style={{ width: "24px", height: "24px" }}
        onError={e => {
            e.target.src = "/images/avatar.png";
        }}
    />;

    return (
        <Row>
            <Col xs={3}>
                <Link to={`/team/${username}`}>
                    {avatar}
                </Link>
                &nbsp;
                {username}
            </Col>
            <Col xs={3}>
                {lastExecutionAt ? lastExecutionAt.substring(0, 10) : ""}
            </Col>
            <Col xs={3}>
                {activeAt ? activeAt.substring(0, 10) : ""}
            </Col>
            <Col xs={3}>
                {createdAt ? createdAt.substring(0, 10) : ""}
            </Col>
        </Row>
    );
}

export default TeamPage;