import { API_BASE_URL, DEFAULT_WORKSPACE } from "../util";
import axios from "axios";

export function getTool(id) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/tools/${id}`;
    return axios.get(url);
}

export function transferTool(id, owner) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/tools/${id}/transfer`;
    return axios.post(url, {owner: owner});
}

export function updateTool(id, updates) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/tools/${id}`;
    return axios.patch(url, updates);
}

export function getTools(query, order, cursor) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/tools?limit=100`;
    if (query !== null && query !== undefined && query !== "")
        url = url + "&q=" + encodeURIComponent(query);
    if (order !== null && order !== undefined)
        url = url + "&order=" + encodeURIComponent(order);
    if (cursor !== null && cursor !== undefined)
        url = url + "&cursor=" + encodeURIComponent(cursor);
    return axios.get(url);
}

export function createBlob(file) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/blobs`;
    return axios.post(url, file, {
        headers: {
            "x-blob-filename": file.name
        }
    });
}

export function createExecution(toolId, args, inputs) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/tools/${toolId}/execute`;
    return axios.post(url, {
        arguments: args.map(ai => ({
            name: ai.name,
            value: ai.value
        })),
        inputs: inputs.map(ii => ({
            name: ii.name,
            data: {
                type: "blob",
                blob: ii.value
            }
        }))
    });
}