import React from "react";
import {
    useState
} from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { createSecret } from "../secret/services.jsx";

function CreateSecretWorkspaceSettingsPane(props) {
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [creating, setCreating] = useState(false);

    const navigate = useNavigate();

    const populated = name !== "" && value !== "";

    return <>
        <h2><Link to="..">Secrets</Link> / Create secret</h2>
        <hr />
        <h3>Name</h3>
        <Form.Control
            className="mb-3"
            placeholder="SECRET_NAME"
            disabled={creating}
            value={name}
            onChange={e => setName(e.target.value)}
        />
        <h3>Value</h3>
        <Form.Control
            className="mb-3"
            as="textarea"
            rows={3}
            placeholder="New secret value"
            disabled={creating}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
        <Button
            disabled={creating || !populated}
            onClick={e => {
                setCreating(true);
                createSecret(name, value)
                    .then(response => {
                        setCreating(false);
                        navigate("..", { relative: "path" });
                    })
                    .catch(error => {
                        setCreating(false);
                        console.log(error);
                    })
            }}
        >
            Save Changes
        </Button>
    </>;
}

export default CreateSecretWorkspaceSettingsPane;