import React from "react";
import {
    useState
} from "react";
import {
    useExecutionsPage
} from "./hooks";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import Image from 'react-bootstrap/Image';
import {
    Link
} from "react-router-dom";
import { useEffect } from "react";

function ExecutionList(props) {
    const { query, owner, state, hideOwnerColumn = false, onLoading } = props;

    // eslint-disable-next-line no-unused-vars
    const [cursor, setCursor] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useExecutionsPage(
        query,
        owner,
        state,
        cursor);

    useEffect(function () {
        onLoading(loading);
    }, [loading]);

    return (
        <div className="tools-container">
            <Container>
                <Row>
                    {
                        hideOwnerColumn
                            ? <></>
                            : <Col xs={2}>
                                <h6>Owner</h6>
                            </Col>
                    }
                    <Col xs={hideOwnerColumn ? 5 : 3}>
                        <h6>Execution Name</h6>
                    </Col>
                    <Col xs={4}>
                        <h6>Tool Name</h6>
                    </Col>
                    <Col xs={2}>
                        <h6>Started At</h6>
                    </Col>
                    <Col xs={1}>
                        <h6>State</h6>
                    </Col>
                </Row>
                {loading
                    ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => <ExecutionPlaceholder hideOwnerColumn={hideOwnerColumn} />)
                    : data.items.map(ei => <ExecutionCard
                        key={ei.id}
                        toolId={ei.tool.id}
                        toolName={ei.tool.name}
                        executionId={ei.id}
                        executionOwnerId={ei.owner}
                        executionOwnerUsername={ei.owner.username}
                        executionOwnerPicture={ei.owner.picture}
                        executionName={ei.name}
                        executionState={ei.state}
                        executionCreatedAt={ei.createdAt}
                        hideOwnerColumn={hideOwnerColumn}
                    />)
                }
            </Container>
        </div>
    );
}

function ExecutionPlaceholder(props) {
    const {
        hideOwnerColumn
    } = props;

    const avatar = <Image
        src="/images/avatar.png"
        roundedCircle={true}
        fluid={true}
        style={{ width: "24px", height: "24px" }}
    />;

    return (
        <Row>
            {
                hideOwnerColumn
                    ? <></>
                    : <Col xs={2}>
                        {avatar}
                        {" "}
                        <Placeholder as="span" animation="wave">
                            <Placeholder xs={8} />
                        </Placeholder>
                    </Col>
            }
            <Col xs={hideOwnerColumn ? 5 : 3}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
            <Col xs={4}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
            <Col xs={2}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
            <Col xs={1}>
                <Placeholder as="span" animation="wave">
                    <Placeholder className="w-100" />
                </Placeholder>
            </Col>
        </Row >
    );
}

function ExecutionCard(props) {
    const {
        toolId,
        toolName,
        executionId,
        // eslint-disable-next-line no-unused-vars
        executionOwnerId,
        executionOwnerUsername,
        executionOwnerPicture,
        executionName,
        executionState,
        executionCreatedAt,
        hideOwnerColumn
    } = props;

    const avatar = <Image
        src={executionOwnerPicture}
        roundedCircle={true}
        fluid={true}
        style={{ width: "24px", height: "24px" }}
        onError={e => {
            e.target.src = "/images/avatar.png";
        }}
    />;

    return (
        <Row>
            {
                hideOwnerColumn
                    ? <></>
                    : <Col xs={2}>
                        <Link to={`/team/${executionOwnerUsername}`}>
                            {avatar}
                        </Link>
                        &nbsp;
                        {executionOwnerUsername}
                    </Col>
            }
            <Col xs={hideOwnerColumn ? 5 : 3}>
                <Link to={`/executions/${executionId}`}>{executionName}</Link>
            </Col>
            <Col xs={4}>
                <Link to={`/tools/${toolId}`}>{toolName}</Link>
            </Col>
            <Col xs={2}>
                {executionCreatedAt.substring(0, 10)}
            </Col>
            <Col xs={1}>
                {executionState}
            </Col>
        </Row >
    );
}

export default ExecutionList;