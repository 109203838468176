import { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { TOKEN_COOKIE_NAME, TOKEN_COOKIE_DOMAIN } from '../util';

export default function AuthCallback(props) {    
    const {
      destination = "/"
    } = props;

    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_NAME]);
  
    const navigate = useNavigate();
    const hash = document.location.hash;
  
    useEffect(function () {
      if (hash !== "") {
        const params = new URLSearchParams(hash.substring(1, hash.length));
        if (params.get("id_token") !== null) {
          const expires = new Date();
          expires.setTime(expires.getTime() + 1000 * 60 * 60);
          console.log("setCookie");
          setCookie(TOKEN_COOKIE_NAME, params.get("id_token"), {
            path: "/",
            expires: expires,
            domain: TOKEN_COOKIE_DOMAIN,
            httpOnly: false
          });
        }
      }
      navigate(destination);
      // eslint-disable
    }, []);
  
    return (<p>Redirecting you...</p>);
  }