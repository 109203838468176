import React from "react";
import {
    useState,
    useEffect,
    useRef
} from "react";
import {
    useTool
} from "./hooks";
import {
    useParams,
    useNavigate
} from "react-router-dom";
import {
    BsCloudArrowUp,
    BsX
} from "react-icons/bs";
import {
    createBlob,
    createExecution
} from "./services";
import Spinner from "react-bootstrap/Spinner";
import ToolPlaceholder from "./ToolPlaceholder";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import FormSelect from 'react-bootstrap/FormSelect';
import FormControl from 'react-bootstrap/FormControl';

function ToolExecutePage(props) {
    const { toolId } = useParams();

    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useTool(toolId);

    return (
        loading
            ? <ToolPlaceholder />
            : <ToolBody {...data} />
    );
}

function ToolBody(props) {
    const { id, name, parameters, inputs } = props;

    const navigate = useNavigate();
    const [parameterEnableds, setParameterEnableds] = useState(Array(parameters.length).fill(null));
    const [parameterValues, setParameterValues] = useState(Array(parameters.length).fill(null));
    const [inputValues, setInputValues] = useState(Array(inputs.length).fill(null));

    const parametersReady = parameters.map(function (pi, i) {
        const ei = parameterEnableds[i];
        const vi = parameterValues[i];
        return !ei || vi !== null;
    }).every(Boolean);

    const inputsReady = inputs.map(function (ii, i) {
        const vi = inputValues[i];
        return vi !== null;
    }).every(Boolean);

    return (
        <div className="tool-body mt-3">
            <Container>
                <Row>
                    <Col>
                        <h1>{name}</h1>
                    </Col>
                    <Col xs={2}>
                        <Button
                            variant="primary"
                            size="lg"
                            disabled={!parametersReady || !inputsReady}
                            onClick={function () {
                                createExecution(
                                    id,
                                    parameters.map(function (pi, i) {
                                        return { name: pi.name, value: parameterValues[i] };
                                    }).filter(function (pi, i) {
                                        return parameterEnableds[i];
                                    }),
                                    inputs.map(function (ii, i) {
                                        return { name: ii.name, value: inputValues[i] };
                                    }))
                                    .then(function (response) {
                                        const execution = response.data;
                                        navigate("/executions/" + execution.id);
                                    })
                                    .catch(function (error) {
                                        // TODO Toast
                                        console.log(error);
                                    });
                            }}
                        >
                            Execute
                        </Button>
                    </Col>
                </Row>
            </Container>
            <ToolParameterSection
                parameters={parameters}
                onEnabledChange={function (newParameterEnableds) {
                    setParameterEnableds(newParameterEnableds);
                }}
                onValueChange={function (newParameterValues) {
                    setParameterValues(newParameterValues);
                }}
            />
            <ToolInputSection
                inputs={inputs}
                onChange={function (newInputValues) {
                    setInputValues(newInputValues);
                }}
            />
        </div>
    );
}

function ToolParameterSection(props) {
    const {
        parameters,
        onEnabledChange = function (enableds) { },
        onValueChange = function (values) { }
    } = props;

    const [enableds, setEnableds] = useState(parameters.map(pi => pi.required));
    useEffect(function () {
        onEnabledChange(enableds);
    }, [enableds]);

    const [values, setValues] = useState(parameters.map(pi => pi.default));
    useEffect(function () {
        onValueChange(values);
    }, [values]);

    return parameters.length === 0
        ? <></>
        : <>
            {
                parameters.map(function (pi, i) {
                    return <ToolParameter
                        {...pi}
                        key={"parameter-" + pi.name}
                        onEnabledChange={function (ei) {
                            setEnableds(enableds.slice(0, i)
                                .concat([ei])
                                .concat(enableds.slice(i + 1, enableds.length)));
                        }}
                        onValueChange={function (vi) {
                            setValues(values.slice(0, i)
                                .concat([vi])
                                .concat(values.slice(i + 1, values.length)));
                        }}
                    />;
                })
            }
        </>;
}

function ToolParameter(props) {
    const {
        type,
        name,
        description,
        required,
        onEnabledChange = function (enabled) { },
        onValueChange = function (value) { }
    } = props;

    const [enabledChecked, setEnabledChecked] = useState(required);

    let control;
    switch (type) {
        case 'string':
            control = <StringToolParameterControl
                name={name}
                domain={props.domain}
                disabled={!enabledChecked}
                default={props.default}
                onChange={onValueChange}
            />;
            break;
        case 'int':
            control = <IntToolParameterControl
                name={name}
                disabled={!enabledChecked}
                default={props.default}
                minimum={props.minimum}
                maximum={props.maximum}
                onChange={onValueChange}
            />;
            break;
        case 'float':
            control = <FloatToolParameterControl
                name={name}
                disabled={!enabledChecked}
                default={props.default}
                minimum={props.minimum}
                maximum={props.maximum}
                onChange={onValueChange}
            />;
            break;
        case 'boolean':
            control = <BooleanToolParameterControl
                name={name}
                disabled={!enabledChecked}
                default={props.default}
                onChange={onValueChange}
            />;
            break;
        case 'date':
            control = <DateToolParameterControl
                name={name}
                disabled={!enabledChecked}
                default={props.default}
                minimum={props.minimum}
                maximum={props.maximum}
                onChange={onValueChange}
            />;
            break;
        default:
            throw new Error("unrecognized tool parameter type " + type);
    }

    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={4}>
                                <Form>
                                    <div onClick={e => e.stopPropagation()} style={{ display: "inline-block" }}>
                                        <Form.Check
                                            type="switch"
                                            inline
                                            checked={enabledChecked}
                                            disabled={required}
                                            onChange={function (e) {
                                                setEnabledChecked(!enabledChecked);
                                                onEnabledChange(!enabledChecked);
                                            }}
                                        />
                                    </div>
                                    <span style={{ fontSize: "150%" }}>{name}</span>
                                </Form>
                            </Col>
                            <Col xs={8} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    {control}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}

function StringToolParameterControl(props) {
    const { name, default: defaultValue, disabled, domain, onChange } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [textValue, setTextValue] = useState(defaultValue);

    let result;
    switch (domain.type) {
        case "pattern":
            result = <FormControl
                type="text"
                disabled={disabled}
                id={"parameter-" + name + "-value"}
                value={textValue}
                onChange={e => {
                    setTextValue(e.target.value);
                }}
                onBlur={e => {
                    const newValue = e.target.value;
                    if (new RegExp(domain.pattern).test(newValue)) {
                        setCurrentValue(newValue);
                        onChange(newValue);
                        if (String(newValue) !== e.target.value) {
                            // The onchange will not re-trigger this onblur
                            setTextValue(String(newValue));
                        }
                    }
                    else {
                        setTextValue(currentValue);
                    }
                }}
            />;
            break;
        case "enumeration":
            result = <FormSelect
                type="text"
                disabled={disabled}
                id={"parameter-" + name + "-value"}
                value={currentValue}
                onChange={e => { setCurrentValue(e.target.value); onChange(e.target.value); }}
            >
                {domain.values.map(value => <option value={value}>{value}</option>)}
            </FormSelect>
            break;
        default:
            throw new Error("unrecognized tool string parameter domain type " + domain.type);
    }

    return result;
}

function IntToolParameterControl(props) {
    const { name, default: defaultValue, minimum, maximum, disabled, onChange } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [textValue, setTextValue] = useState(defaultValue.toString());

    return <FormControl
        type="number"
        pattern="[-+]?\\d+"
        step={1}
        min={minimum}
        max={maximum}
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={textValue}
        onChange={e => {
            setTextValue(e.target.value);
        }}
        onBlur={e => {
            if (/^[-+]?[0-9]+$/.test(e.target.value)) {
                const newValue = parseInt(e.target.value);
                if (newValue >= minimum && newValue <= maximum) {
                    setCurrentValue(newValue);
                    onChange(newValue);
                    if (String(newValue) !== e.target.value) {
                        // The onchange will not re-trigger this onblur
                        setTextValue(String(newValue));
                    }
                }
                else {
                    setTextValue(currentValue);
                }
            }
            else {
                setTextValue(currentValue);
            }
        }}
    />;
}

function FloatToolParameterControl(props) {
    const { name, default: defaultValue, minimum, maximum, disabled, onChange } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [textValue, setTextValue] = useState(defaultValue.toString());

    // TODO Should we adjust step based on range of min/max/default?
    return <FormControl
        type="number"
        pattern="[-+]?\\d+(?:[.]\\d*)?(?:[eE][-+]?\\d+)?"
        step={1}
        min={minimum}
        max={maximum}
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={textValue}
        onChange={e => {
            setTextValue(e.target.value);
        }}
        onBlur={e => {
            if (/^[-+]?\d+(?:[.]\d*)?(?:[eE][-+]?\d+)?$/.test(e.target.value)) {
                const newValue = parseFloat(e.target.value);
                if (newValue >= minimum && newValue <= maximum) {
                    setCurrentValue(newValue);
                    onChange(newValue);
                }
                else {
                    setTextValue(currentValue);
                }
            }
            else {
                setTextValue(currentValue);
            }
        }}
    />;
}

function BooleanToolParameterControl(props) {
    const { name, default: defaultValue, disabled, onChange } = props;

    const [value, setValue] = useState((!!defaultValue).toString());

    return <FormCheck
        style={{ marginTop: "8px" }}
        type="checkbox"
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={value}
        reverse
        onChange={e => {
            const newValue = e.target.value.toLowerCase().startsWith("t");
            setValue(newValue);
            onChange(newValue);
        }}
    />;
}

function DateToolParameterControl(props) {
    const { name, default: defaultValue, minimum, maximum, disabled, onChange } = props;

    const [value, setValue] = useState(defaultValue);

    // TODO We need to interpret the expressions for the execution
    // TODO We need to validate, like for the int type
    // TODO Convert to date type
    return <FormControl
        type="date"
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={value}
        min={minimum}
        max={maximum}
        onChange={e => { setValue(e.target.value); onChange(e.target.value); }}
    />;
}

function ToolInputSection(props) {
    const {
        inputs,
        onChange = function (values) { }
    } = props;

    const [values, setValues] = useState(Array(inputs.length).fill(null));

    return inputs.length === 0
        ? <></>
        : <>
            {
                inputs.map(function (ii, i) {
                    return <ToolInput
                        {...ii}
                        key={"input-" + ii.name}
                        onChange={function (vi) {
                            const newValues = values.slice(0, i)
                                .concat([vi])
                                .concat(values.slice(i + 1, values.length));
                            setValues(newValues);
                            onChange(newValues);
                        }}
                    />;
                })
            }
        </>;
}

const INPUT_STATE_EMPTY = 0;
const INPUT_STATE_FILLING = 1;
const INPUT_STATE_FILLED = 2;

function ToolInput(props) {
    const {
        name,
        description,
        onChange = function (value) { }
    } = props;

    const [state, setState] = useState(INPUT_STATE_EMPTY);
    // eslint-disable-next-line no-unused-vars
    const [blobId, setBlobId] = useState(null);

    const fileInput = useRef(null);

    let buttonDisabled, buttonIcon, buttonAction;
    switch (state) {
        case INPUT_STATE_EMPTY:
            buttonDisabled = false;
            buttonIcon = <BsCloudArrowUp color="black" />;
            buttonAction = function (e) {
                fileInput.current.click();
            };
            break;
        case INPUT_STATE_FILLING:
            buttonDisabled = true;
            buttonIcon = <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
            />;
            buttonAction = function (e) {
                // NOP, we're disabled
            };
            break;
        case INPUT_STATE_FILLED:
            buttonDisabled = false;
            buttonIcon = <BsX color="black" />;
            buttonAction = function (e) {
                setState(INPUT_STATE_EMPTY);
                setBlobId(null);
                onChange(null);
                fileInput.current.value = null;
            };
            break;
        default:
            throw new Error("unrecognized state " + state);
    }

    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={11}>
                                <span style={{ fontSize: "150%" }}>{name}</span>
                            </Col>
                            <Col xs={1} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    <input
                                        type="file"
                                        ref={fileInput}
                                        style={{ display: "none" }}
                                        onChange={function (event) {
                                            setState(INPUT_STATE_FILLING);
                                            const file = event.target.files[0];
                                            createBlob(file)
                                                .then(function (response) {
                                                    const newBlobId = response.data.id;
                                                    setState(INPUT_STATE_FILLED);
                                                    setBlobId(newBlobId);
                                                    onChange(newBlobId);
                                                })
                                                .catch(function (error) {
                                                    // TODO Toast
                                                    console.log(error);
                                                });
                                        }}
                                    />
                                    <Button
                                        href={"#input-" + name}
                                        size="sm"
                                        variant="outline-secondary"
                                        onClick={buttonAction}
                                        disabled={buttonDisabled}
                                    >
                                        {buttonIcon}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default ToolExecutePage;