import React from "react";
import {
    useEffect,
    useState
} from "react";
import {
    getContainer,
    transferContainer,
    updateContainer,
    getContainerVersionsPage,
    releaseContainerVersion,
    deprecateContainerVersion
} from "./services";
import {
    getAccounts
} from "../team/services"
import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";
import {
    BsGearFill,
    BsHouseDoorFill,
    BsTagsFill
} from "react-icons/bs";
import ContainerPlaceholder from "./ContainerPlaceholder";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import {
    useAuth
} from '../auth/AuthProvider';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import ReactCommonmark from "react-commonmark";
import { useContainerPushLogs } from "./hooks";

function ContainerPage(props) {
    const { containerId } = useParams();

    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();

    const [containerLoading, setContainerLoading] = useState(true);
    const [containerReloading, setContainerReloading] = useState(false);
    const [containerData, setContainerData] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [containerError, setContainerError] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [currentVersion, setCurrentVersion] = useState(null);

    useEffect(function () {
        setContainerReloading(true);
        getContainer(containerId)
            .then(function (response) {
                setContainerLoading(false);
                setContainerReloading(false);
                setContainerData(response.data);
                setCurrentVersion(response.data.currentVersion);
            })
            .catch(function (error) {
                setContainerLoading(false);
                setContainerReloading(false);
                setContainerError(error);
            });
    }, [containerId, timestamp]);

    // Do a PATCH request to update the container description
    function doSetContainerDescription(description) {
        setContainerReloading(true);
        updateContainer(containerId, { description })
            .then(function (response) {
                setContainerReloading(false);
                setContainerData(response.data);
            })
            .catch(function (error) {
                setContainerReloading(false);
                setContainerError(error);
            });
    }

    // Do a PATCH request to update the container name. This changes the container URL, so do a navigate
    // to the new container URL afterwards.
    function doSetContainerName(name) {
        setContainerReloading(true);
        updateContainer(containerId, { name })
            .then(function (response) {
                // TODO When this actually changes URLs, then do a navigate.
                // navigate("/containers/" + response.data.slug);
                setContainerReloading(false);
                setContainerData(response.data);
            })
            .catch(function (error) {
                setContainerReloading(false);
                setContainerError(error);
            });
    }

    function doTransferContainer(owner) {
        setContainerReloading(true);
        transferContainer(containerId, owner.id)
            .then(function (response) {
                setContainerReloading(false);
                setContainerData(response.data);
            })
            .catch(function (error) {
                setContainerReloading(false);
                setContainerError(error);
            });
    }

    function doReleaseContainerVersion(versionId) {
        setContainerReloading(true);
        releaseContainerVersion(containerId, versionId)
            .then(function (response) {
                setTimestamp(Date.now());
            })
            .catch(function error() {
                setTimestamp(Date.now());
                setContainerError(error);
            });
    }

    function doDeprecateContainerVersion(versionId) {
        setContainerReloading(true);
        deprecateContainerVersion(containerId, versionId)
            .then(function (response) {
                setTimestamp(Date.now());
            })
            .catch(function error() {
                setTimestamp(Date.now());
                setContainerError(error);
            });
    }

    const reloading = containerReloading;

    return (
        containerLoading
            ? <ContainerPlaceholder />
            : <ContainerBody
                ownerId={containerData.owner.id}
                ownerUsername={containerData.owner.username}
                ownerPicture={containerData.owner.picture}
                id={containerId}
                version={currentVersion}
                name={containerData.name}
                description={containerData.description}
                repository={containerData.repository}
                parameters={currentVersion ? currentVersion.parameters : null}
                inputs={currentVersion ? currentVersion.inputs : null}
                outputs={currentVersion ? currentVersion.outputs : null}
                reloading={reloading}
                onDescriptionChange={doSetContainerDescription}
                onRename={doSetContainerName}
                onTransfer={doTransferContainer}
                onReleaseVersion={doReleaseContainerVersion}
                onDeprecateVersion={doDeprecateContainerVersion}
                containerDataVersion={timestamp}
            />
    );
}

function ContainerBody(props) {
    const {
        // eslint-disable-next-line no-unused-vars
        ownerId,
        ownerUsername,
        ownerPicture,
        id,
        version,
        name,
        description,
        repository,
        parameters,
        inputs,
        outputs,
        reloading,
        onDescriptionChange,
        onRename,
        onTransfer,
        onReleaseVersion,
        onDeprecateVersion,
        containerDataVersion
    } = props;

    const { me } = useAuth();
    const isOwner = me.id === ownerId || me.superuser;

    const avatar = <Image
        src={ownerPicture}
        roundedCircle={true}
        fluid={true}
        style={{ width: "28px", height: "28px" }}
        onError={e => {
            e.target.src = "/images/avatar.png";
        }}
    />;

    return (
        <div className="container-body mt-3">
            <Container>
                <Row>
                    <Col>
                        <h1>
                            <Link to={`/team/${ownerUsername}`}>
                                {avatar}
                            </Link>
                            &nbsp;
                            {name}
                        </h1>
                    </Col>
                    <Col xs={3} style={{ textAlign: "right" }}>
                        <Button
                            as={Link}
                            variant="primary"
                            size="lg"
                            to={"/containers/" + id + "/install"}
                        >
                            Install this container
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-3">
                        { /* TODO Fix CommonMark */}
                        <ReactCommonmark source={description} />
                    </Col>
                </Row>
            </Container>
            <Tabs
                defaultActivityKey="overview"
                id="container-tabs"
                className="mb-3"
                fill
            >
                <Tab
                    eventKey="overview"
                    title={<span><BsHouseDoorFill /> Overview</span>}
                >
                    <ContainerOverview
                        id={id}
                        description={description}
                        parameters={parameters}
                        inputs={inputs}
                        outputs={outputs}
                        deprecated={!version}
                    />
                </Tab>
                <Tab
                    eventKey="versions"
                    title={<span><BsTagsFill /> Versions</span>}
                    disabled={!isOwner}
                >
                    <ContainerVersions
                        id={id}
                        containerDataVersion={containerDataVersion}
                        onReleaseVersion={onReleaseVersion}
                        onDeprecateVersion={onDeprecateVersion}
                    />
                </Tab>
                <Tab
                    eventKey="settings"
                    title={<span><BsGearFill /> Settings</span>}
                    disabled={!isOwner}
                >
                    <ContainerSettings
                        id={id}
                        ownerId={ownerId}
                        version={version}
                        name={name}
                        description={description}
                        repository={repository}
                        reloading={reloading}
                        onDescriptionChange={onDescriptionChange}
                        onTransfer={onTransfer}
                        onRename={onRename}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

function ContainerVersions(props) {
    const {
        id: containerId,
        onReleaseVersion,
        onDeprecateVersion,
        containerDataVersion
    } = props;

    const [versionsPageLoading, setVersionsPageLoading] = useState(true);
    const [versionsPageReloading, setVersionsPageReloading] = useState(false);
    const [versionsPageData, setVersionsPageData] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [versionsPageError, setVersionsPageError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [currentVersionsPageCursor, setCurrentVersionsPageCursor] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [nextVersionsPageCursor, setNextVersionsPageCursor] = useState(null);

    useEffect(function () {
        setVersionsPageReloading(true);
        getContainerVersionsPage(containerId, currentVersionsPageCursor)
            .then(function (response) {
                setVersionsPageLoading(false);
                setVersionsPageReloading(false);
                setVersionsPageData(response.data);
                setNextVersionsPageCursor(response.data.cursor);
            })
            .catch(function (error) {
                setVersionsPageLoading(false);
                setVersionsPageReloading(false);
                setVersionsPageError(error);
                setNextVersionsPageCursor(null);
            });
    }, [containerId, currentVersionsPageCursor, containerDataVersion]);

    // eslint-disable-next-line no-unused-vars
    const [pushLogsLoading, pushLogsData, pushLogsError, pushLogsRefresh] = useContainerPushLogs(containerId);

    return versionsPageLoading || pushLogsLoading
        ? <p>Loading...</p>
        : <>
            <Row className="mt-3">
                <Col xs={9}>
                    <h2>Versions</h2>
                </Col>
                <Col xs={3} style={{ textAlign: "right" }}>
                    <Button
                        className="ms-3"
                        variant="danger"
                        onClick={e => {
                            onDeprecateVersion(selectedVersion);
                            setSelectedVersion(null);
                        }}
                        disabled={versionsPageReloading || selectedState !== "current"}
                    >
                        Deprecate
                    </Button>
                    <Button
                        className="ms-3"
                        variant="primary"
                        onClick={e => {
                            onReleaseVersion(selectedVersion);
                            setSelectedVersion(null);
                        }}
                        disabled={versionsPageReloading || selectedState !== "ready"}
                    >
                        Release
                    </Button>
                </Col>
            </Row>
            <Table
                striped
                bordered
                hover
                responsive
                className="mt-3"
            >
                <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Created By</th>
                        <th>Created At</th>
                        <th>State</th>
                    </tr>
                </thead>
                <tbody>
                    {versionsPageData.items.map(v =>
                        <tr>
                            <td>
                                <FormCheck
                                    name="selectedVersion"
                                    type="radio"
                                    id={`selectedVersion-${v.version}`}
                                    checked={selectedVersion === v.version}
                                    onClick={e => {
                                        setSelectedVersion(v.version);
                                        setSelectedState(v.state);
                                    }}
                                    disabled={v.state !== "ready" && v.state !== "current"}
                                />
                            </td>
                            <td>{v.version}</td>
                            <td>{v.creator.username}</td>
                            <td>{v.createdAt}</td>
                            <td>{v.state}</td>
                        </tr>)
                    }
                </tbody>
            </Table>
            <Row className="mt-3">
                <Col xs={9}>
                    <h2>Recent Pushes</h2>
                </Col>
                <Col xs={3} style={{ textAlign: "right" }}>
                </Col>
            </Row>
            <Table
                striped
                bordered
                hover
                responsive
                className="mt-3"
            >
                <thead>
                    <tr>
                        <th>Digest</th>
                        <th>Timestamp</th>
                        <th>Outcome</th>
                    </tr>
                </thead>
                <tbody>
                    {pushLogsData.items.map(l =>
                        <tr>
                            <td>{l.digest.split(":", 2)[1]}</td>
                            <td>{l.timestamp}</td>
                            <td>{l.outcome}</td>
                        </tr>)
                    }
                </tbody>
            </Table>
        </>;
}

function ContainerSettings(props) {
    const {
        ownerId,
        version,
        name: containerName,
        description: containerDescription,
        repository,
        onDescriptionChange,
        onTransfer,
        onRename,
        reloading
    } = props;

    const [localDescription, setLocalDescription] = useState(containerDescription);

    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const { me } = useAuth();
    const permitted = me.id === ownerId;

    return (
        <>
            <h3>General</h3>
            <Form>
                <Form.Group
                    className="mb-3"
                    controlId="containerDescription"
                >
                    <Form.Label>Container Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        value={localDescription}
                        onChange={(e) => setLocalDescription(e.target.value)}
                        onBlur={(e) => onDescriptionChange(e.target.value)}
                        disabled={reloading || !permitted}
                    />
                    <Form.Text muted>
                        The description is in <a style={{ "color": "inherit" }} href="https://commonmark.org" target="_blank" rel="noreferrer">commonmark</a> format.
                    </Form.Text>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="containerVersion"
                >
                    <Form.Label>Current Version</Form.Label>
                    <Form.Control
                        type="input"
                        placeholder="Current Version"
                        value={version ? version.version : null}
                        disabled={true}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="dockerRepository"
                >
                    <Form.Label>Docker Repository</Form.Label>
                    <Form.Control
                        type="input"
                        placeholder="Docker Repository"
                        value={repository}
                        disabled={true}
                    />
                </Form.Group>
            </Form>
            <h3>Danger Zone</h3>
            <Container className="rounded-1 border border-danger p-2 mb-3">
                <Row className="mt-3">
                    <Col xs={10}>
                        <h6>Rename</h6>
                        <p>Change this container's name. This changes the container's URL, which breaks external links to the container! Name changes are usually reversible.</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowRenameModal(true)}
                            disabled={reloading || !permitted}
                        >
                            Rename
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col xs={10}>
                        <h6>Transfer Ownership</h6>
                        <p>Transfer ownership of this container to another user. After the transfer is complete, you will not be able to administrate the container or transfer it back to yourself!</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowTransferModal(true)}
                            disabled={reloading || !permitted}
                        >
                            Transfer
                        </Button>
                    </Col>
                </Row>
            </Container>
            <ContainerRenameModal
                show={showRenameModal}
                setShow={setShowRenameModal}
                name={containerName}
                onConfirm={onRename}
            />
            <ContainerTransferModal
                show={showTransferModal}
                setShow={setShowTransferModal}
                onConfirm={onTransfer}
            />
        </>);
}

function ContainerRenameModal(props) {
    const {
        name: currentName,
        onConfirm,
        show,
        setShow
    } = props;

    const [newName, setNewName] = useState(currentName);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setNewName(currentName);
        setConfirmed(false);
    }, [show]);

    const changed = newName !== currentName;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm(newName);
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Rename Container?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you rename this container, then its URL will change, which will break external links to the container!
                </Alert>

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="containerRenameConfirm"
                    >
                        <Form.Label>New Container Name</Form.Label>
                        <Form.Control
                            placeholder="New Container Name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </Form.Group>
                </Form>

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="containerRenameConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                            disabled={!changed}
                        />
                        <Form.Label>Please check here to confirm the rename.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Rename
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ContainerTransferModal(props) {
    const {
        onConfirm,
        show,
        setShow
    } = props;

    const [newOwner, setNewOwner] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setNewOwner(null);
        setConfirmed(false);
    }, [show]);

    const empty = newOwner === null;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm(newOwner);
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Transfer Container?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you transfer this container to another user, then you will not be able to administrate it or transfer it back to yourself afterwards!
                </Alert>

                <h6>New Owner's Username</h6>
                <UserSearchBox
                    onChange={account => {
                        if (account) {
                            setNewOwner(account);
                        }
                        else {
                            setConfirmed(false);
                            setNewOwner(null);
                        }
                    }}
                />

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="containerTransferConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                            disabled={empty}
                        />
                        <Form.Label>Please check here to confirm the transfer.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Transfer
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function UserSearchBox(props) {
    const {
        onChange
    } = props;

    const { me } = useAuth();

    const [loading, setLoading] = useState(false);
    const [hits, setHits] = useState([]);

    function handleSearch(query) {
        setLoading(true);
        getAccounts(query)
            .then(response => {
                setLoading(false);
                setHits(response.data.items);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    };

    return (
        <AsyncTypeahead
            // We don't want to transfer to ourselves. So filter ourselves out.
            filterBy={hit => hit.id !== me.id}
            id="username-lookup"
            isLoading={loading}
            labelKey="username"
            minLength={1}
            onSearch={handleSearch}
            onChange={items => onChange(items[0])}
            options={hits}
            placeholder="Search username..."
            renderMenuItemChildren={option => (
                <>
                    <img
                        alt={option.username}
                        src={option.picture}
                        style={{
                            height: '24px',
                            marginRight: '10px',
                            width: '24px',
                        }}
                    />
                    <span>{option.username}</span>
                </>
            )}
        />
    );
}

function ContainerOverview(props) {
    const {
        parameters,
        inputs,
        outputs,
        deprecated
    } = props;
    console.log("deprecated = " + deprecated);
    return (
        deprecated
            ? <p>This tool is currently deprecated.</p>
            : <>
                <ContainerParameterSection parameters={parameters} />
                <ContainerInputSection inputs={inputs} />
                <ContainerOutputSection outputs={outputs} />
            </>
    );
}

function ContainerParameterSection(props) {
    const { parameters } = props;
    return parameters == null || parameters.length === 0
        ? <></>
        : <>
            <h2>Parameters</h2>
            {
                parameters.map(pi => <ContainerParameter {...pi} />)
            }
        </>;
}

function ContainerParameter(props) {
    const { name, description } = props;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    {name}
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

function ContainerInputSection(props) {
    const { inputs } = props;
    return inputs == null || inputs.length === 0
        ? <></>
        : <>
            <h2>Inputs</h2>
            {
                inputs.map(ii => <ContainerInput {...ii} />)
            }
        </>;
}

function ContainerInput(props) {
    const { name, description } = props;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    {name}
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

function ContainerOutputSection(props) {
    const { outputs } = props;
    return outputs == null || outputs.length === 0
        ? <></>
        : <>
            <h2>Outputs</h2>
            {
                outputs.map(oi => <ContainerOutput {...oi} />)
            }
        </>;
}

function ContainerOutput(props) {
    const { name, description } = props;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    {name}
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

export default ContainerPage;