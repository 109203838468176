import {
  createContext,
  useContext,
  useState,
  useEffect
} from "react";

import { useCookies } from 'react-cookie';
import axios from "axios";
import { API_BASE_URL, TOKEN_COOKIE_NAME } from '../util';

const AuthContext = createContext();

export function AuthProvider({
  children
}) {
  const [auth, setAuth] = useState({ loading: true });

  const [me, setMe] = useState({ loading: true, authenticated: false });

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_COOKIE_NAME]);

  const logout = () => {
    console.log("logout");
    removeCookie(TOKEN_COOKIE_NAME);
    setAuth({ loading: false });
    setMe({ loading: false, authenticated: false });
  };

  useEffect(function () {
    if (cookies[TOKEN_COOKIE_NAME]) {
      const token = cookies[TOKEN_COOKIE_NAME];
      setAuth({ loading: true });
      fetch(API_BASE_URL + "/accounts/me", {
        headers: {
          authorization: "bearer " + token
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        data.superuser = data.roles && data.roles.indexOf("superuser") !== -1;
        setAuth({
          ...data,
          loading: true,
          token: token
        });
      }).catch(function (error) {
        console.log(error);
        setAuth({ loading: false });
      });
    }
    else {
      setAuth({ loading: false });
    }
  }, [cookies]);

  useEffect(function () {
    let requestInterceptor, responseInterceptor;
    if (auth.token) {
      // Make sure our requests are authenticated
      requestInterceptor = axios.interceptors.request.use(function (config) {
        config.headers.authorization = "bearer " + auth.token;
        return config;
      }, function (error) {
        // TODO Do something with request error
        return Promise.reject(error);
      });

      // Make sure we're still authenticated
      responseInterceptor = axios.interceptors.response.use(function (response) {
        // 2xx
        return response;
      }, function (error) {
        // Other
        if (error.response.status === 401) {
          // Looks like our credentials have expired. Time to refresh them.
          logout();
        }
        return Promise.reject(error);
      });

      setMe({ ...auth, loading: false, authenticated: true });
    }
    else {
      requestInterceptor = responseInterceptor = null;

      setMe({ loading: auth.loading, authenticated: false });
    }
    return function () {
      if (requestInterceptor)
        axios.interceptors.request.eject(requestInterceptor);
      if (responseInterceptor)
        axios.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable
  }, [auth]);

  return (
    <AuthContext.Provider value={{ me, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext);