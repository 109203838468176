import React from "react";
import {
    useState
} from "react";
import {
    useContainersPage
} from "./hooks";
import Form from "react-bootstrap/Form";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import {
    Link
} from "react-router-dom";
import {
    BsPlus
} from "react-icons/bs";
import { useEffect } from "react";

import ReactCommonmark from "react-commonmark";

function ContainersPage(props) {
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(true);
    return (
        <>
            <Row className="mb-3 mt-3">
                <Col xs={11}>
                    <Form.Control
                        type="text"
                        placeholder="Keyword Search Query"
                        disabled={loading}
                        onBlur={e => setQuery(e.target.value)}
                        onKeyUp={e => {
                            if (e.key === "Enter") {
                                e.target.blur();
                            }
                        }}
                    />
                </Col>
                <Col xs={1}>
                    <Button
                        className="w-100"
                        as={Link}
                        variant="primary"
                        to={"/containers/new"}
                    >
                        <BsPlus size="18pt" />
                    </Button>
                </Col>
            </Row>
            <ContainerList
                query={query}
                onLoading={(loading) => {
                    setLoading(loading);
                }}
            />
        </>
    );
}

function ContainerList(props) {
    const { query, onLoading } = props;

    // eslint-disable-next-line no-unused-vars
    const [cursor, setCursor] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useContainersPage(query, cursor);

    useEffect(function () {
        onLoading(loading);
    }, [loading]);

    return (
        <div className="tools-container">
            {loading
                ? [1, 2, 3].map(ci => <ContainerPlaceholder />)
                : data.items.map(ci => <ContainerCard
                    key={ci.id}
                    id={ci.id}
                    name={ci.name}
                    description={ci.description}
                    ownerId={ci.owner.id}
                    ownerUsername={ci.owner.username}
                    ownerPicture={ci.owner.picture}
                />)
            }
        </div>
    );
}

function ContainerPlaceholder(props) {
    const avatar = <Image
        src="/images/avatar.png"
        roundedCircle={true}
        fluid={true}
        style={{ width: "18px", height: "18px" }}
    />;
    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    <Row>
                        <Col>
                            {avatar}
                            {" "}
                            <Placeholder as="span" animation="wave">
                                <Placeholder xs={3} size="lg" />
                            </Placeholder>
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                            <Button
                                variant="primary"
                                size="sm"
                                disabled={true}
                            >
                                Install this tool
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={12} />
                    </Placeholder>
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

function ContainerCard(props) {
    const {
        id,
        name,
        description,
        ownerUsername,
        ownerPicture
    } = props;

    const avatar = <Image
        src={ownerPicture}
        roundedCircle={true}
        fluid={true}
        style={{ width: "18px", height: "18px" }}
        onError={e => {
            e.target.src = "/images/avatar.png";
        }}
    />;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    <Row>
                        <Col>
                            <Link to={`/team/${ownerUsername}`}>
                                {avatar}
                            </Link>
                            &nbsp;
                            <Link to={"/containers/" + id}>{name}</Link>
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                            <Button
                                as={Link}
                                variant="primary"
                                size="sm"
                                to={"/containers/" + id + "/install"}
                            >
                                Install this tool
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    { /* TODO Fix CommonMark */ }
                    <ReactCommonmark source={description} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

export default ContainersPage;