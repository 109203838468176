import {
  AUTH_URL,
  PROFILE
} from './util';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import {
  useAuth
} from './auth/AuthProvider';
import Image from 'react-bootstrap/Image';
import {
  useEffect,
  useState
} from 'react';

function AppLayout() {
  return (
    <>
      { PROFILE==="prod" ? <Intercom /> : <></> }
      <AppNavbar />
      <AppBody />
    </>
  );
}

function Intercom(props) {
  const auth = useAuth();
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(function () {
    if (auth?.me?.authenticated !== authenticated) {
      let payload = {
        api_base: "https://api-iam.intercom.io",
        app_id: "j3r88ov3"
      };
      if (auth?.me?.authenticated) {
        payload.user_id = auth.me.id;
        payload.name = auth.me.name;
        payload.email = auth.me.email;
        payload.created_at = String(Date.parse(auth.me.createdAt) / 1000);
      }
      if (window.Intercom)
        window.Intercom("boot", payload);
      setAuthenticated(auth?.me?.authenticated);
    }
  }, [auth, authenticated]);

  // Have to use document.location for tests
  const location = document.location;

  useEffect(function () {
    if (window.Intercom)
      window.Intercom("update");
  }, [location]);

  return <></>;
}

function AppNavbar(props) {
  // Workaround for testing
  const auth = useAuth();
  return auth?.me?.loading || !auth?.me?.authenticated ? <AnonymousAppNavbar /> : <AuthenticatedAppNavbar />;
}

function AnonymousAppNavbar(props) {
  return <Navbar bg="light" expand="lg" className="mb-3">
    <Container>
      <Navbar.Brand href="/">ToolForge</Navbar.Brand>
    </Container>
  </Navbar>;
}

function AuthenticatedAppNavbar(props) {
  const { me, logout } = useAuth();

  const superuser = me.superuser;

  const navigate = useNavigate();

  const navDropdownTitle = <Image
    src={me.picture}
    roundedCircle={true}
    fluid={true}
    style={{ maxWidth: "32px", maxHeight: "32px" }}
    onError={e => {
      e.target.src = "/images/avatar.png";
    }}
  />;

  const location = useLocation();

  const section = location.pathname === "/"
    ? "home"
    : location.pathname.substring(1).split("/", 2)[0];

  return <Navbar bg="light" expand="lg" className="mb-3">
    <Container>
      <Navbar.Brand as={Link} to="/">ToolForge</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto" activeKey={section}>
          {
            superuser
              ? <Nav.Item className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/containers"
                  className="nav-link"
                  eventKey="containers"
                >
                  Containers
                </Nav.Link>
              </Nav.Item>
              : <></>
          }
          <Nav.Item className="nav-item">
            <Nav.Link
              as={Link}
              to="/tools"
              className="nav-link"
              eventKey="tools"
            >
              Tools
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link
              as={Link}
              to="/executions"
              className="nav-link"
              eventKey="executions"
            >
              Executions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link
              as={Link}
              to="/team"
              className="nav-link"
              eventKey="team"
            >
              Team
            </Nav.Link>
          </Nav.Item>
          {
            superuser
              ? <Nav.Item className="nav-item">
                <Nav.Link
                  as={Link}
                  to="/wssettings"
                  className="nav-link"
                  eventKey="wssettings"
                >
                  Settings
                </Nav.Link>
              </Nav.Item>
              : <></>
          }
        </Nav>
        <Nav>
          <NavDropdown title={navDropdownTitle} eventKey={3} id="basic-nav-dropdown">
            <NavDropdown.Item
              as={Link}
              to="/settings"
            >
              Settings
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={(e) => {
                logout();
                navigate("/");
                e.preventDefault();
              }}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
}

function AppBody() {
  // Workaround for testing
  const auth = useAuth();

  let body;
  if (auth?.me?.loading) {
    body = <LoadingAppBody />;
  } else if (auth?.me?.authenticated) {
    body = <AuthenticatedAppBody />;
  } else {
    body = <AnonymousAppBody />;
  }

  return body;
}

function LoadingAppBody(props) {
  return <p className="text-center">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </p>;
}

function AuthenticatedAppBody(props) {
  const location = useLocation();
  return location.pathname === "/"
    ? <Navigate to="/tools" />
    : <Container><Outlet /></Container>;
}

function AnonymousAppBody(props) {
  // Weird workaround for testing.
  // const location=useLocation();
  const location = document.location;
  return location.pathname === "/"
    ? <Container><LoginAppBody /></Container>
    : <Navigate to="/" />;
}

function LoginAppBody(props) {
  return <p className="text-center">
    <Button
      variant="primary"
      href={AUTH_URL}
      size="lg"
    >
      Login
    </Button>
  </p>;
}

export default AppLayout;
