import { API_BASE_URL, DEFAULT_WORKSPACE } from "../util";
import axios from "axios";

export function getExecution(id) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${id}`;
    return axios.get(url);
}

export function transferExecution(id, owner) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${id}/transfer`;
    return axios.post(url, {owner: owner});
}

export function updateExecution(id, updates) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${id}`;
    return axios.patch(url, updates);
}

export function getExecutions(query, owner, state, cursor) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions?limit=100`;
    if (query !== null && query !== undefined && query !== "")
        url = url + "&q=" + encodeURIComponent(query);
    if (owner !== null && owner !== undefined)
        url = url + "&owner=" + encodeURIComponent(owner);
    if (state !== null && state !== undefined)
        url = url + "&state=" + encodeURIComponent(state);
    if (cursor !== null && cursor !== undefined)
        url = url + "&cursor=" + encodeURIComponent(cursor);
    return axios.get(url);
}

export function getExecutionLogs(id, cursor) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${id}/logs?limit=10000`;
    if (cursor !== null && cursor !== undefined)
        url = url + "&cursor=" + encodeURIComponent(cursor);
    return axios.get(url);
}

export function cancelExecution(id) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${id}/cancel`;
    return axios.post(url);
}

export function deleteExecution(id) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${id}`;
    return axios.delete(url);
}