import React from "react";
import {
    useLocation,
    Link,
    Outlet
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from 'react-bootstrap/Nav';

function AccountSettingsLayout(props) {
    return <>
        <Container>
            <Row>
                <Col xs={2}>
                    <AccountSettingsNav />
                </Col>
                <Col xs={10}>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    </>;
}

function AccountSettingsNav(props) {
    const location = useLocation();

    const section = location.pathname.substring(1).split("/")[1];

    return <Nav
        activeKey={section}
        className="flex-column"
        variant="pills"
    >
        <Nav.Link
            as={Link}
            to="/settings/general"
            eventKey="general"
        >
            General
        </Nav.Link>
        <Nav.Link
            as={Link}
            to="/settings/keys"
            eventKey="keys"
        >
            Keys
        </Nav.Link>
    </Nav>;
}

export default AccountSettingsLayout;