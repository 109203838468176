import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import './index.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  AuthProvider
} from './auth/AuthProvider';
import {
  ToastProvider
} from './toast/ToastProvider'
import {
  CookiesProvider
} from 'react-cookie';
import AuthCallback from './auth/AuthCallback';

import AppLayout from "./App";
import TeamAccountPage from "./team/TeamAccountPage";
import ContainerPage from "./container/ContainerPage";
import ContainerInstallPage from "./container/ContainerInstallPage";
import ContainersPage from "./container/ContainersPage";
import ContainerCreatePage from "./container/ContainerCreatePage";
import ExecutionPage from "./execution/ExecutionPage";
import ExecutionsPage from "./execution/ExecutionsPage";
import ToolExecutePage from "./tool/ToolExecutePage";
import ToolPage from "./tool/ToolPage";
import ToolsPage from "./tool/ToolsPage";
import WorkspaceSettingsLayout from "./workspace/WorkspaceSettingsLayout";
import GeneralWorkspaceSettingsPane from "./workspace/GeneralWorkspaceSettingsPane";
import SecretsWorkspaceSettingsPane from "./workspace/SecretsWorkspaceSettingsPane";
import SecretWorkspaceSettingsPane from "./workspace/SecretWorkspaceSettingsPane";
import CreateSecretWorkspaceSettingsPane from "./workspace/CreateSecretWorkspaceSettingsPane";
import AccountSettingsLayout from "./account/AccountSettingsLayout";
import GeneralAccountSettingsPane from "./account/GeneralAccountSettingsPane";
import KeysAccountSettingsPane from "./account/KeysAccountSettingsPane";
import CreateApiKeyAccountSettingsPane from "./account/CreateApiKeyAccountSettingsPane";
import TeamPage from "./team/TeamPage";
import NotFound from "./common/NotFound"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route exact path="/auth/cognito/callback" element={<AuthCallback destination="/" />} />

      <Route element={<AppLayout />}>
        <Route path="containers">
          <Route index element={<ContainersPage />} />
          <Route path="new" element={<ContainerCreatePage />} />
          <Route path=":containerId">
            <Route index element={<ContainerPage />} />
            <Route path="install" element={<ContainerInstallPage />} />
          </Route>
        </Route>

        <Route path="executions">
          <Route index element={<ExecutionsPage />} />
          <Route path=":executionId" element={<ExecutionPage />} />
        </Route>

        <Route path="team">
          <Route index element={<TeamPage />} />
          <Route path=":username" element={<TeamAccountPage />} />
        </Route>

        <Route path="tools">
          <Route index element={<ToolsPage />} />
          <Route path=":toolId">
            <Route index element={<ToolPage />} />
            <Route path="execute" element={<ToolExecutePage />} />
          </Route>
        </Route>

        <Route path="wssettings" element={<WorkspaceSettingsLayout />}>
          <Route index element={<Navigate replace to="general" />} />
          <Route path="general" element={<GeneralWorkspaceSettingsPane />} />
          <Route path="secrets">
            <Route index element={<SecretsWorkspaceSettingsPane />} />
            <Route path=":secretName" element={<SecretWorkspaceSettingsPane />} />
            <Route path="new" element={<CreateSecretWorkspaceSettingsPane />} />
          </Route>
        </Route>

        <Route path="settings" element={<AccountSettingsLayout />}>
          <Route index element={<Navigate replace to="general" />} />
          <Route path="general" element={<GeneralAccountSettingsPane />} />
          <Route path="keys">
            <Route index element={<KeysAccountSettingsPane />} />
            <Route path="api/new" element={<CreateApiKeyAccountSettingsPane />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

function RootLayout(props) {
  return <Outlet />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <ToastProvider>
          <script src="https://unpkg.com/react/umd/react.production.min.js" crossorigin></script>
          <script
            src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
            crossorigin></script>
          <script
            src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
            crossorigin></script>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css"
            integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor"
            crossorigin="anonymous"
          />
          <RouterProvider router={router} />
        </ToastProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
