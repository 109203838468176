import {
    useEffect,
    useState
} from "react";
import {
    getContainer,
    getContainers,
    getContainerVersionsPage,
    getContainerPushLogs
} from "./services";

export function useContainersPage(query, cursor) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getContainers(query, cursor)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [query, cursor]);

    return [loading, data, error];
}

export function useContainerVersionsPage(cursor) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getContainerVersionsPage(cursor)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [cursor]);

    return [loading, data, error];
}

export function useContainerPushLogs(id) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());

    useEffect(function () {
        setLoading(true);
        getContainerPushLogs(id)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [id, timestamp]);

    const refresh=() => setTimestamp(Date.now());

    return [loading, data, error, refresh];
}

export function useContainer(id) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getContainer(id)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [id]);

    return [loading, data, error];
}