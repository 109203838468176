import React from "react";
import {
    useState
} from "react";
import {
    useNavigate
} from "react-router-dom";
import {
    createToolContainer
} from "./services";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';

function ContainerCreatePage(props) {
    const [containerName, setContainerName] = useState("");
    const [containerDescription, setContainerDescription] = useState("");

    const ready = containerName.trim() !== "" && containerDescription.trim() !== "";

    const navigate = useNavigate();

    function doCreateContainer() {
        createToolContainer(containerName, containerDescription)
            .then(function (response) {
                const container=response.data;
                navigate("/containers/" + container.id);
            })
            .catch(function (error) {
                // TODO TOAST
                console.log(error);
            });
    }

    return <>
        <h1>Create Container</h1>
        <Form>
            <Form.Group className="mb-3" controlId="container-name">
                <Form.Label>Container Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter container name"
                    value={containerName}
                    onChange={e => setContainerName(e.target.value) }
                />
                <Form.Text className="text-muted">
                    The name affects how your container will appear in the marketplace and its URL, so
                    choose carefully! You can change your tool's name later.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="container-description">
                <Form.Label>Container Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter container description"
                    value={containerDescription}
                    onChange={e => setContainerDescription(e.target.value) }
                />
                <Form.Text className="text-muted">
                    The description is in <a style={{ "color": "inherit" }} href="https://commonmark.org" target="_blank" rel="noreferrer">commonmark</a> format.
                </Form.Text>
            </Form.Group>

            <Button
                disabled={!ready}
                onClick={doCreateContainer}
            >
                Create Container
            </Button>
        </Form>
    </>;
}

export default ContainerCreatePage;