import React from "react";
import {
    useState,
    useEffect
} from "react";
import {
    useParams,
    useNavigate
} from "react-router-dom";
import {
    installToolContainer
} from "./services";
import {
    useContainer
} from "./hooks";
import {
    getSecrets
} from "../secret/services";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { DEFAULT_WORKSPACE } from "../util";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

function ContainerInstallPage(props) {
    const { containerId } = useParams();

    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useContainer(containerId);

    let result;
    if (loading) {
        result = <ContainerInstallPlaceholder />;
    } else
        if (data.currentVersion == null) {
            result = <p>This tool cannot be installed because it has no current version.</p>;
        }
        else {
            result = <ContainerInstallBody
                id={containerId}
                name={data.name}
                secrets={data.currentVersion.secrets}
                variables={data.currentVersion.variables}
            />;
        }

    return result;
}

function ContainerInstallPlaceholder() {
    return <p>Loading...</p>;
}

function ContainerInstallBody(props) {
    // eslint-disable-next-line no-unused-vars
    const { id, name, secrets, variables } = props;

    const navigate = useNavigate();

    const [secretEnableds, setSecretEnableds] = useState(secrets.map(si => si.required));
    const [secretValues, setSecretValues] = useState(secrets.map(si => null));

    const secretsReady = secrets.map(function (si, i) {
        const ei = secretEnableds[i];
        const vi = secretValues[i];
        return !ei || vi !== null;
    }).every(Boolean);

    const [variableEnableds, setVariableEnableds] = useState(variables.map(vi => vi.required));
    const [variableValues, setVariableValues] = useState(variables.map(vi => vi.default || ""));

    const variablesReady = secrets.map(function (vi, i) {
        const ei = variableEnableds[i];
        const xi = variableValues[i];
        return !ei || xi !== "";
    }).every(Boolean);

    return (
        <div className="tool-body mt-3">
            <h1>{name}</h1>
            <ContainerInstallSecretSection
                secrets={secrets}
                onEnabledChange={function (newSecretEnableds) {
                    setSecretEnableds(newSecretEnableds);
                }}
                onValueChange={function (newSecretValues) {
                    setSecretValues(newSecretValues);
                }}
            />
            <ContainerInstallVariableSection
                variables={variables}
                onEnabledChange={function (newVariableEnableds) {
                    setVariableEnableds(newVariableEnableds);
                }}
                onValueChange={function (newVariableValues) {
                    setVariableValues(newVariableValues);
                }}
            />
            <Button
                variant="primary"
                size="lg"
                disabled={!secretsReady || !variablesReady}
                onClick={function () {
                    installToolContainer(
                        id,
                        DEFAULT_WORKSPACE,
                        secrets.map(function (si, i) {
                            return { name: si.name, secret: secretValues[i]?.id };
                        }).filter(function (si, i) {
                            return secretEnableds[i];
                        }),
                        variables.map(function (vi, i) {
                            return { name: vi.name, value: variableValues[i] };
                        }).filter(function (vi, i) {
                            return variableEnableds[i];
                        }))
                        .then(function (response) {
                            const installedTool = response.data;
                            navigate("/tools/" + installedTool.tool.id);
                        })
                        .catch(function (error) {
                            // TODO Toast
                            console.log(error);
                        });
                }}
            >
                Install
            </Button>

        </div>
    );
}

function ContainerInstallSecretSection(props) {
    const {
        secrets,
        onEnabledChange,
        onValueChange
    } = props;

    const [enableds, setEnableds] = useState(secrets.map(si => si.required));
    useEffect(function () {
        onEnabledChange(enableds);
    }, [enableds]);

    const [values, setValues] = useState(secrets.map(si => null));
    useEffect(function () {
        onValueChange(values);
    }, [values]);

    return <>
        <h2>Secrets</h2>
        {secrets != null && secrets.length === 0
            ? <p>This container requires no secrets.</p>
            : <>
                {
                    secrets.map(function (si, i) {
                        return <ContainerInstallSecret
                            {...si}
                            key={"secret-" + si.name}
                            onEnabledChange={function (ei) {
                                setEnableds(enableds.slice(0, i)
                                    .concat([ei])
                                    .concat(enableds.slice(i + 1, enableds.length)));
                            }}
                            onValueChange={function (vi) {
                                setValues(values.slice(0, i)
                                    .concat([vi])
                                    .concat(values.slice(i + 1, values.length)));
                            }}
                        />;
                    })
                }
            </>
        }
    </>;
}

function ContainerInstallSecret(props) {
    const {
        name,
        description,
        required,
        onEnabledChange = () => { },
        onValueChange = (value) => { }
    } = props;

    const [enabledChecked, setEnabledChecked] = useState(required);

    const control = <ContainerInstallSecretControl
        name={name}
        disabled={!enabledChecked}
        default={props.default}
        onChange={value => onValueChange(value || null)}
    />;

    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={4}>
                                <Form>
                                    <div
                                        onClick={e => e.stopPropagation()}
                                        style={{ display: "inline-block" }}
                                    >
                                        <Form.Check
                                            type="switch"
                                            inline
                                            checked={enabledChecked}
                                            disabled={required}
                                            onChange={function (e) {
                                                setEnabledChecked(!enabledChecked);
                                                onEnabledChange(!enabledChecked);
                                            }}
                                        />
                                    </div>
                                    <span style={{ fontSize: "150%" }}>{name}</span>
                                </Form>
                            </Col>
                            <Col xs={8} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    {control}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}

function ContainerInstallSecretControl(props) {
    const {
        name,
        disabled,
        onChange
    } = props;
    return <SecretSearchBox
        name={name}
        onChange={onChange}
        disabled={disabled}
    />;
}

function SecretSearchBox(props) {
    const {
        name,
        disabled,
        onChange
    } = props;

    const [loading, setLoading] = useState(false);
    const [hits, setHits] = useState([]);

    function handleSearch(query) {
        setLoading(true);
        getSecrets(query)
            .then(response => {
                setLoading(false);
                setHits(response.data.items);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    };

    return (
        <AsyncTypeahead
            id={"secret-" + name + "-lookup"}
            isLoading={loading}
            labelKey="name"
            minLength={0}
            onSearch={handleSearch}
            onChange={items => onChange(items[0])}
            options={hits}
            disabled={disabled}
            placeholder="Search secret name..."
            renderMenuItemChildren={option => (
                <>
                    <span>{option.name}</span>
                </>
            )}
        />
    );
}


function ContainerInstallVariableSection(props) {
    const {
        variables,
        onEnabledChange,
        onValueChange
    } = props;

    const [enableds, setEnableds] = useState(variables.map(vi => vi.required));
    useEffect(function () {
        onEnabledChange(enableds);
    }, [enableds]);

    const [values, setValues] = useState(variables.map(vi => vi.default));
    useEffect(function () {
        onValueChange(values);
    }, [values]);

    return <>
        <h2>Variables</h2>
        {variables != null && variables.length === 0
            ? <p>This container requires no variables.</p>
            : <>
                {
                    variables.map(function (vi, i) {
                        return <ContainerInstallVariable
                            {...vi}
                            key={"variable-" + vi.name}
                            onEnabledChange={function (ei) {
                                setEnableds(enableds.slice(0, i)
                                    .concat([ei])
                                    .concat(enableds.slice(i + 1, enableds.length)));
                            }}
                            onValueChange={function (xi) {
                                setValues(values.slice(0, i)
                                    .concat([xi])
                                    .concat(values.slice(i + 1, values.length)));
                            }}
                        />;
                    })
                }
            </>}
    </>;
}

function ContainerInstallVariable(props) {
    const {
        name,
        description,
        required,
        default: defaultValue,
        onEnabledChange = () => { },
        onValueChange = (value) => { }
    } = props;

    const [enabledChecked, setEnabledChecked] = useState(required);

    const control = <ContainerInstallVariableControl
        name={name}
        disabled={!enabledChecked}
        default={defaultValue}
        onChange={value => onValueChange(value)}
    />;

    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={4}>
                                <Form>
                                    <div
                                        onClick={e => e.stopPropagation()}
                                        style={{ display: "inline-block" }}
                                    >
                                        <Form.Check
                                            type="switch"
                                            inline
                                            checked={enabledChecked}
                                            disabled={required}
                                            onChange={function (e) {
                                                setEnabledChecked(!enabledChecked);
                                                onEnabledChange(!enabledChecked);
                                            }}
                                        />
                                    </div>
                                    <span style={{ fontSize: "150%" }}>{name}</span>
                                </Form>
                            </Col>
                            <Col xs={8} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    {control}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}

function ContainerInstallVariableControl(props) {
    const {
        name,
        default: defaultValue,
        disabled,
        onChange
    } = props;

    const [textValue, setTextValue] = useState(defaultValue);

    return <FormControl
        type="text"
        disabled={disabled}
        id={"variable-" + name + "-value"}
        value={textValue}
        onChange={e => {
            setTextValue(e.target.value);
        }}
        onBlur={e => {
            const newValue = e.target.value;
            setTextValue(newValue);
            onChange(newValue);
        }}
    />;
}

export default ContainerInstallPage;