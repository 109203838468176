import React from "react";
import {
    useState
} from "react";
import {
    Link, useLocation
} from "react-router-dom";
import {
    BsLockFill,
    BsTrashFill,
    BsKeyFill,
    BsClipboard,
    BsClipboardCheck
} from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {
    useMyApiKeys
} from "./hooks.jsx";
import { deleteMyApiKey } from "./services.jsx";
import {
    BsPlus
} from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import Placeholder from 'react-bootstrap/Placeholder';

function KeysAccountSettingsPane(props) {
    const [timestamp, setTimestamp] = useState(Date.now());

    // eslint-disable-next-line no-unused-vars
    const [loading, data, error] = useMyApiKeys(timestamp);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingApiKey, setDeletingApiKey] = useState(null);
    const location = useLocation();
    const newApiKey = location.state?.newApiKey;

    // We want this secret value to go away on refresh or navigation, so we
    // replace it directly. React Router won't support directly.
    // https://stackoverflow.com/a/66359848/2103602
    // https://github.com/remix-run/react-router/issues/8673
    window.history.replaceState({}, "");

    return <>
        <h2>Keys</h2>
        <hr />
        {
            newApiKey
                ? <Alert key="primary" variant="primary">
                    Don't forget to copy your new key below. This is the last time it will be shown!
                </Alert>
                : <></>
        }
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    <Row>
                        <Col xs={11}>
                            API Keys
                        </Col>
                        <Col xs={1}>
                            <Button
                                className="w-100"
                                as={Link}
                                variant="primary"
                                to={"api/new"}
                                size="sm"
                            >
                                <BsPlus size="14pt" />
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {
                        loading
                            ? [0, 1, 2].map(xi => <ApiKeyRowPlaceholder />)
                            : data.items.map(ki => {
                                function onDelete() {
                                    setDeletingApiKey(ki);
                                    setShowDeleteModal(true);
                                }
                                let result;
                                if (newApiKey?.id === ki.id) {
                                    result = <NewApiKeyRow
                                        id={ki.id}
                                        token={newApiKey.token}
                                        onDelete={onDelete}
                                    />;
                                }
                                else {
                                    result = <ApiKeyRow
                                        id={ki.id}
                                        description={ki.description}
                                        activeAt={ki.activeAt}
                                        onDelete={onDelete}
                                    />;
                                }
                                return result;
                            })
                    }
                </Card.Text>
            </Card.Body>
        </Card>
        <DeleteApiKeyModal
            apiKey={deletingApiKey}
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            onConfirm={e => {
                deleteMyApiKey(deletingApiKey.id)
                    .then(function () {
                        setTimestamp(Date.now());
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
            }}
        />
    </>;
}

function NewApiKeyRow(props) {
    const { id, token, onDelete } = props;

    const [ copied, setCopied ] = useState(false);

    return <Row className="p-1 bg-primary text-white" key={id}>
        <Col xs={8}>
            <BsKeyFill />
            {" "}
            {token}
            {" "}
            {copied
                ? <BsClipboardCheck
                    style={{ cursor: "pointer" }}
                    title="Copy"
                    onClick={() => {
                        navigator.clipboard.writeText(token);
                    }} />
                : <BsClipboard
                    style={{ cursor: "pointer" }}
                    title="Copy"
                    onClick={() => {
                        setCopied(true);
                        navigator.clipboard.writeText(token);
                    }}
                />
            }
        </Col>
        <Col xs={4} style={{ textAlign: "right" }}>
            <Button
                onClick={onDelete}
                variant="light"
                size="sm"
            >
                <BsTrashFill />
            </Button>
        </Col>
    </Row>;
}

function ApiKeyRow(props) {
    const { id, description, activeAt, onDelete } = props;
    return <Row className="p-1" key={id}>
        <Col xs={8}>
            <BsKeyFill />
            {" "}
            {description}
        </Col>
        <Col xs={4} style={{ textAlign: "right" }}>
            Last used {activeAt ? activeAt.slice(0, 10) : "never"}
            {" "}
            <Button
                onClick={onDelete}
                variant="secondary"
                size="sm"
            >
                <BsTrashFill />
            </Button>
        </Col>
    </Row>;
}


function ApiKeyRowPlaceholder(props) {
    return <Row className="mb-1">
        <Col xs={8}>
            <BsLockFill />
            {" "}
            <Placeholder as="span" animation="wave">
                <Placeholder xs={6} size="lg" />
            </Placeholder>
        </Col>
        <Col xs={4} style={{ textAlign: "right" }}>
            <Placeholder as="span" animation="wave">
                <Placeholder xs={6} size="lg" />
            </Placeholder>
            {" "}
            <Button
                variant="secondary"
                size="sm"
                disabled={true}
            >
                <BsTrashFill />
            </Button>
        </Col>
    </Row>;
}

function DeleteApiKeyModal(props) {
    const {
        apiKey,
        onConfirm,
        show,
        setShow
    } = props;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm();
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Delete API Key?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you delete this API key, then other applications might stop working!
                </Alert>
                <p>
                    Are you sure you want to delete API key for {apiKey?.description}?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                >
                    Yes, Delete this API Key.
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default KeysAccountSettingsPane;