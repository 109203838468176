export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/v1";

export const AUTH_URL = process.env.REACT_APP_AUTH_URL || "http://localhost:8080/auth";

export const TOKEN_COOKIE_NAME = "token";

export const TOKEN_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "localhost";

export const DEFAULT_WORKSPACE = process.env.REACT_APP_DEFAULT_WORKSPACE || "default0";

export const PROFILE = process.env.REACT_APP_PROFILE || "local";

export const CLIENT = process.env.REACT_APP_CLIENT || "local";