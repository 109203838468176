import {
    API_BASE_URL,
    DEFAULT_WORKSPACE
} from "../util";
import axios from "axios";

export function getSecrets() {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/secrets`;
    return axios.get(url);
}

export function getSecretByName(secretName) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/secrets/by/name/${secretName}`;
    return axios.get(url);
}

export function updateSecret(id, updates) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/secrets/${id}`;
    return axios.patch(url, updates);
}

export function createSecret(name, value) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/secrets`;
    return axios.post(url, { name, value });
}

export function deleteSecret(id) {
    let url = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/secrets/${id}`;
    return axios.delete(url);
}