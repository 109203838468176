import { API_BASE_URL } from "../util";
import axios from "axios";

export function getContainer(id) {
    let url = `${API_BASE_URL}/containers/${id}`;
    return axios.get(url);
}

export function transferContainer(id, owner) {
    let url = `${API_BASE_URL}/containers/${id}/transfer`;
    return axios.post(url, {owner: owner});
}

export function updateContainer(id, updates) {
    let url = `${API_BASE_URL}/containers/${id}`;
    return axios.patch(url, updates);
}

export function getContainers(query, cursor) {
    let url = `${API_BASE_URL}/containers?limit=100`;
    if (query !== null && query !== undefined && query !== "")
        url = url + "&q=" + encodeURIComponent(query);
    if (cursor !== null && cursor !== undefined)
        url = url + "&cursor=" + encodeURIComponent(cursor);
    return axios.get(url);
}

export function getContainerVersionsPage(id, cursor) {
    let url = `${API_BASE_URL}/containers/${id}/versions?limit=100`;
    if(cursor !== null && cursor !== undefined)
        url = url + "&cursor=" + encodeURIComponent(cursor);
    return axios.get(url);
}

export function getContainerVersion(containerId, versionId) {
    let url = `${API_BASE_URL}/containers/${containerId}/versions/${versionId}`;
    return axios.get(url);
}

export function releaseContainerVersion(containerId, versionId) {
    let url=`${API_BASE_URL}/containers/${containerId}/versions/${versionId}/release`;
    return axios.post(url);
}

export function deprecateContainerVersion(containerId, versionId) {
    let url=`${API_BASE_URL}/containers/${containerId}/versions/${versionId}/deprecate`;
    return axios.post(url);
}

export function installToolContainer(containerId, workspaceId, secrets, variables) {
    let url=`${API_BASE_URL}/containers/${containerId}/install`;
     return axios.post(url, { type: "tool", workspace: workspaceId, secrets, variables });
}

export function createToolContainer(name, description) {
    let url=`${API_BASE_URL}/containers`;
    return axios.post(url, { type: "tool", name, description });
}

export function getContainerPushLogs(containerId) {
    let url=`${API_BASE_URL}/containers/${containerId}/logs/push`;
    return axios.get(url);
}