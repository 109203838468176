import React from "react";
import {
    useEffect,
    useState
} from "react";
import {
    getTool,
    updateTool,
    transferTool
} from "./services";
import {
    getAccounts
} from "../team/services"
import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";
import {
    BsGearFill,
    BsHouseDoorFill
} from "react-icons/bs";
import ToolPlaceholder from "./ToolPlaceholder";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import {
    useAuth
} from '../auth/AuthProvider';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import ReactCommonmark from "react-commonmark";

function ToolPage(props) {
    const { toolId } = useParams();

    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();

    const [toolLoading, setToolLoading] = useState(true);
    const [toolReloading, setToolReloading] = useState(false);
    const [toolData, setToolData] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [toolError, setToolError] = useState(null);

    useEffect(function () {
        setToolReloading(true);
        getTool(toolId)
            .then(function (response) {
                setToolLoading(false);
                setToolReloading(false);
                setToolData(response.data);
            })
            .catch(function (error) {
                setToolLoading(false);
                setToolReloading(false);
                setToolError(error);
            });
    }, [toolId]);

    // Do a PATCH request to update the tool description
    // TODO Add this as a feature, please...
    function doSetToolDescription(description) {
        setToolReloading(true);
        updateTool(toolId, { description })
            .then(function (response) {
                setToolReloading(false);
                setToolData(response.data);
            })
            .catch(function (error) {
                setToolReloading(false);
                setToolError(error);
            });
    }

    // Do a PATCH request to update the tool name. This changes the tool URL, so do a navigate to
    // the new tool URL afterwards.
    function doSetToolName(name) {
        setToolReloading(true);
        updateTool(toolId, { name })
            .then(function (response) {
                // TODO When this actually changes URLs, then do a navigate.
                // navigate("/tools/" + response.data.slug);
                setToolReloading(false);
                setToolData(response.data);
            })
            .catch(function (error) {
                setToolReloading(false);
                setToolError(error);
            });
    }

    function doTransferTool(owner) {
        setToolReloading(true);
        transferTool(toolId, { owner: owner.id })
            .then(function (response) {
                setToolReloading(false);
                setToolData(response.data);
            })
            .catch(function (error) {
                setToolReloading(false);
                setToolError(error);
            });
    }

    const reloading = toolReloading;

    return (
        toolLoading
            ? <ToolPlaceholder />
            : <ToolBody
                ownerId={toolData.owner.id}
                ownerUsername={toolData.owner.username}
                ownerPicture={toolData.owner.picture}
                id={toolData.id}
                version={toolData.currentVersion}
                name={toolData.name}
                description={toolData.description}
                parameters={toolData.parameters}
                inputs={toolData.inputs}
                outputs={toolData.outputs}
                reloading={reloading}
                onDescriptionChange={doSetToolDescription}
                onRename={doSetToolName}
                onTransfer={doTransferTool}
            />
    );
}

function ToolBody(props) {
    const {
        // eslint-disable-next-line no-unused-vars
        ownerId,
        ownerUsername,
        ownerPicture,
        id,
        version,
        name,
        description,
        parameters,
        inputs,
        outputs,
        reloading,
        onDescriptionChange,
        onRename,
        onTransfer
    } = props;

    const { me } = useAuth();
    const isOwner = me.id === ownerId || me.superuser;

    return (
        <div className="tool-body mt-3">
            <Container>
                <Row>
                    <Col>
                        <h1>
                            <Link to={`/team/${ownerUsername}`}>
                                <img alt="" src={ownerPicture} style={{ width: "28px" }} />
                            </Link>
                            &nbsp;
                            {name}
                        </h1>
                    </Col>
                    <Col xs={2}>
                        <Button
                            as={Link}
                            variant="primary"
                            size="lg"
                            to={"/tools/" + id + "/execute"}
                        >
                            Run this tool
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-3">
                        <ReactCommonmark source={description} />
                    </Col>
                </Row>
            </Container>
            <Tabs
                defaultActivityKey="overview"
                id="tool-tabs"
                className="mb-3"
                fill
            >
                <Tab
                    eventKey="overview"
                    title={<span><BsHouseDoorFill /> Overview</span>}
                >
                    <ToolOverview
                        description={description}
                        parameters={parameters}
                        inputs={inputs}
                        outputs={outputs}
                    />
                </Tab>
                <Tab
                    eventKey="settings"
                    title={<span><BsGearFill /> Settings</span>}
                    disabled={!isOwner}
                >
                    <ToolSettings
                        ownerId={ownerId}
                        version={version}
                        name={name}
                        description={description}
                        reloading={reloading}
                        onDescriptionChange={onDescriptionChange}
                        onTransfer={onTransfer}
                        onRename={onRename}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

function ToolSettings(props) {
    const {
        ownerId,
        version,
        name: toolName,
        onTransfer,
        onRename,
        reloading
    } = props;

    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const { me } = useAuth();
    const permitted = me.id === ownerId;

    return (
        <>
            <h3>General</h3>
            <Form>
                {/*
                <Form.Group
                    className="mb-3"
                    controlId="toolDescription"
                >
                    <Form.Label>Tool Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        value={localDescription}
                        onChange={(e) => setLocalDescription(e.target.value)}
                        onBlur={(e) => onDescriptionChange(e.target.value)}
                        disabled={reloading || !permitted}
                    />
                    <Form.Text muted>
                        The description is in <a style={{ "color": "inherit" }} href="https://commonmark.org" target="_blank" rel="noreferrer">commonmark</a> format.
                    </Form.Text>
                </Form.Group>
                */}
                <Form.Group
                    className="mb-3"
                    controlId="toolVersion"
                >
                    <Form.Label>Tool Description</Form.Label>
                    <Form.Control
                        type="input"
                        placeholder="Tool Version"
                        value={version}
                        disabled={true}
                    />
                </Form.Group>
            </Form>
            <h3>Danger Zone</h3>
            <Container className="rounded-1 border border-danger p-2 mb-3">
                <Row className="mt-3">
                    <Col xs={10}>
                        <h6>Rename</h6>
                        <p>Change this tool's name. This changes the tool's URL, which breaks external links to the tool! Name changes are usually reversible.</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowRenameModal(true)}
                            disabled={reloading || !permitted}
                        >
                            Rename
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col xs={10}>
                        <h6>Transfer Ownership</h6>
                        <p>Transfer ownership of this tool to another user. After the transfer is complete, you will not be able to administrate the tool or transfer it back to yourself!</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowTransferModal(true)}
                            disabled={reloading || !permitted}
                        >
                            Transfer
                        </Button>
                    </Col>
                </Row>
            </Container>
            <ToolRenameModal
                show={showRenameModal}
                setShow={setShowRenameModal}
                name={toolName}
                onConfirm={onRename}
            />
            <ToolTransferModal
                show={showTransferModal}
                setShow={setShowTransferModal}
                onConfirm={onTransfer}
            />
        </>);
}

function ToolRenameModal(props) {
    const {
        name: currentName,
        onConfirm,
        show,
        setShow
    } = props;

    const [newName, setNewName] = useState(currentName);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setNewName(currentName);
        setConfirmed(false);
    }, [show]);

    const changed = newName !== currentName;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm(newName);
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Rename Tool?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you rename this tool, then its URL will change, which will break external links to the tool!
                </Alert>

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="toolRenameConfirm"
                    >
                        <Form.Label>New Tool Name</Form.Label>
                        <Form.Control
                            placeholder="New Tool Name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </Form.Group>
                </Form>

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="toolRenameConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                            disabled={!changed}
                        />
                        <Form.Label>Please check here to confirm the rename.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Rename
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ToolTransferModal(props) {
    const {
        onConfirm,
        show,
        setShow
    } = props;

    const [newOwner, setNewOwner] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setNewOwner(null);
        setConfirmed(false);
    }, [show]);

    const empty = newOwner === null;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm(newOwner);
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Transfer Tool?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you transfer this tool to another user, then you will not be able to administrate it or transfer it back to yourself afterwards!
                </Alert>

                <h6>New Owner's Username</h6>
                <UserSearchBox
                    onChange={account => {
                        if (account) {
                            setNewOwner(account);
                        }
                        else {
                            setConfirmed(false);
                            setNewOwner(null);
                        }
                    }}
                />

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="toolTransferConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                            disabled={empty}
                        />
                        <Form.Label>Please check here to confirm the transfer.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Transfer
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function UserSearchBox(props) {
    const {
        onChange
    } = props;

    const { me } = useAuth();

    const [loading, setLoading] = useState(false);
    const [hits, setHits] = useState([]);

    function handleSearch(query) {
        setLoading(true);
        getAccounts(query)
            .then(response => {
                setLoading(false);
                setHits(response.data.items);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    };

    return (
        <AsyncTypeahead
            // We don't want to transfer to ourselves. So filter ourselves out.
            filterBy={hit => hit.id !== me.id}
            id="username-lookup"
            isLoading={loading}
            labelKey="username"
            minLength={1}
            onSearch={handleSearch}
            onChange={items => onChange(items[0])}
            options={hits}
            placeholder="Search username..."
            renderMenuItemChildren={option => (
                <>
                    <img
                        alt={option.username}
                        src={option.picture}
                        style={{
                            height: '24px',
                            marginRight: '10px',
                            width: '24px',
                        }}
                    />
                    <span>{option.username}</span>
                </>
            )}
        />
    );
}

function ToolOverview(props) {
    const {
        parameters,
        inputs,
        outputs
    } = props;
    return (
        <>
            <ToolParameterSection parameters={parameters} />
            <ToolInputSection inputs={inputs} />
            <ToolOutputSection outputs={outputs} />
        </>
    );
}

function ToolParameterSection(props) {
    const { parameters } = props;
    return parameters.length === 0
        ? <></>
        : <>
            <h2>Parameters</h2>
            {
                parameters.map(pi => <ToolParameter {...pi} />)
            }
        </>;
}

function ToolParameter(props) {
    const { name, description } = props;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    {name}
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

function ToolInputSection(props) {
    const { inputs } = props;
    return inputs.length === 0
        ? <></>
        : <>
            <h2>Inputs</h2>
            {
                inputs.map(ii => <ToolInput {...ii} />)
            }
        </>;
}

function ToolInput(props) {
    const { name, description } = props;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    {name}
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

function ToolOutputSection(props) {
    const { outputs } = props;
    return outputs.length === 0
        ? <></>
        : <>
            <h2>Outputs</h2>
            {
                outputs.map(oi => <ToolOutput {...oi} />)
            }
        </>;
}

function ToolOutput(props) {
    const { name, description } = props;

    return (
        <Card className="mb-3">
            <Card.Header>
                <Container>
                    {name}
                </Container>
            </Card.Header>
            <Card.Body>
                { /* <Card.Title>Special title treatment</Card.Title> */}
                <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Card.Text>
                { /* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    );
}

export default ToolPage;