import {
    useEffect,
    useState
} from "react";
import { getExecution, getExecutions } from "./services";

export function useExecutionsPage(query, owner, state, cursor) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getExecutions(query, owner, state, cursor)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [query, owner, state, cursor]);

    return [loading, data, error];
}
export function useExecution(id) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getExecution(id)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [id]);

    return [loading, data, error];
}