import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ToolPlaceholder(props) {
    return (
        <div className="tool-placeholder">
            <Container>
                <Row>
                    <Col xs={8}>
                        <Placeholder as="h1" animation="glow">
                            <Placeholder bg="dark" xs={12} />
                        </Placeholder>
                    </Col>
                    <Col xs={2} />
                    <Col xs={2}>
                        <Placeholder.Button
                            variant="primary"
                            size="lg"
                            style={{ marginTop: "8px" }}
                            xs={12}
                        />
                    </Col>
                </Row>
            </Container>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
            </Placeholder>
        </div>
    );
}

export default ToolPlaceholder;