import React, { useEffect } from "react";
import { API_BASE_URL, DEFAULT_WORKSPACE } from "../util";
import {
    useState
} from "react";
import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";
import {
    BsCloudArrowDown,
    BsGearFill,
    BsHouseDoorFill
} from "react-icons/bs";
import ExecutionPlaceholder from "./ExecutionPlaceholder";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import SplitButton from 'react-bootstrap/SplitButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FormCheck from 'react-bootstrap/FormCheck';
import FormSelect from 'react-bootstrap/FormSelect';
import FormControl from 'react-bootstrap/FormControl';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
    getContainerVersion
} from "../container/services"
import {
    getAccounts
} from "../team/services"
import {
    getExecution,
    transferExecution,
    updateExecution,
    getExecutionLogs,
    cancelExecution,
    deleteExecution
} from "./services";
import {
    useAuth
} from '../auth/AuthProvider';

const REFRESH_INTERVAL = 15000;

const EXECUTION_STATE_STARTING = "starting";
const EXECUTION_STATE_RUNNING = "running";
const EXECUTION_STATE_SUCCEEDED = "succeeded";
const EXECUTION_STATE_FAILED = "failed";
const EXECUTION_STATE_CANCELLED = "cancelled";

function ExecutionPage(props) {
    const { executionId } = useParams();

    const navigate = useNavigate();

    const [finished, setFinished] = useState(false);
    const [executionLoading, setExecutionLoading] = useState(true);
    const [executionReloading, setExecutionReloading] = useState(false);
    const [executionData, setExecutionData] = useState(null);
    const [executionError, setExecutionError] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [timer, setTimer] = useState(null);

    useEffect(function () {
        setTimer(setInterval(function () {
            setTimestamp(Date.now());
        }, REFRESH_INTERVAL));
        return function () {
            if (timer !== null)
                clearInterval(timer);
        }
    }, []);

    // TODO Reload, including websockets eventually
    useEffect(function () {
        if (finished === false) {
            setExecutionReloading(true);
            getExecution(executionId)
                .then(function (response) {
                    setFinished(response.data.state === EXECUTION_STATE_SUCCEEDED
                        || response.data.state === EXECUTION_STATE_CANCELLED
                        || response.data.state === EXECUTION_STATE_FAILED);
                    setExecutionLoading(false);
                    setExecutionReloading(false);
                    setExecutionData(response.data);
                })
                .catch(function (error) {
                    setExecutionLoading(false);
                    setExecutionReloading(false);
                    setExecutionError(error);
                });
        }
    }, [executionId, timestamp, finished]);

    const [containerLoading, setContainerLoading] = useState(true);
    const [containerData, setContainerData] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [containerError, setContainerError] = useState(null);

    useEffect(function () {
        if (executionData !== null) {
            if (containerData === null || containerData.id !== executionData.tool.id) {
                setContainerLoading(true);
                getContainerVersion(executionData.tool.id, executionData.toolVersion)
                    .then(function (response) {
                        setContainerLoading(false);
                        setContainerData(response.data);
                    })
                    .catch(function (error) {
                        setContainerLoading(false);
                        setContainerError(error);
                    });
            }
        }
        if (executionError !== null) {
            // TODO Toast
            console.log(executionError);
        }
    }, [executionData, executionError]);

    // Do a PATCH request to update the execution name
    function doSetExecutionName(name) {
        setExecutionReloading(true);
        updateExecution(executionId, { name })
            .then(function (response) {
                setExecutionReloading(false);
                setExecutionData(response.data);
            })
            .catch(function (error) {
                setExecutionReloading(false);
                setExecutionError(error);
            });
    }

    function doTransferExecution(owner) {
        setExecutionReloading(true);
        transferExecution(executionId, { owner: owner.id })
            .then(function (response) {
                setExecutionReloading(false);
                setExecutionData(response.data);
            })
            .catch(function (error) {
                setExecutionReloading(false);
                setExecutionError(error);
            });
    }

    function doCancelExecution() {
        setExecutionReloading(true);
        cancelExecution(executionId)
            .then(function (response) {
                setExecutionReloading(false);
                setExecutionData(response.data);
            })
            .catch(function (error) {
                setExecutionReloading(false);
                setExecutionError(error);
            });
    }

    function doDeleteExecution() {
        setExecutionReloading(true);
        deleteExecution(executionId)
            .then(function (response) {
                navigate("/executions");
            })
            .catch(function (error) {
                setExecutionReloading(false);
                setExecutionError(error);
            });
    }

    const loading = executionLoading || containerLoading;
    const reloading = executionReloading;

    return (
        loading
            ? <ExecutionPlaceholder />
            : <ExecutionBody
                toolId={executionData.tool.id}
                toolName={executionData.tool.name}
                toolSlug={executionData.tool.slug}
                ownerId={executionData.owner.id}
                ownerUsername={executionData.owner.username}
                ownerPicture={executionData.owner.picture}
                executionId={executionId}
                executionName={executionData.name}
                onNameChange={doSetExecutionName}
                onOwnerChange={doTransferExecution}
                onCancel={doCancelExecution}
                onDelete={doDeleteExecution}
                state={executionData.state}
                finished={finished}
                reloading={reloading}
                arguments={containerData.parameters.map(function (pi, i) {
                    const arg = executionData.arguments.find(ai => ai.name === pi.name);
                    return {
                        name: pi.name,
                        description: pi.description,
                        type: pi.type,
                        domain: pi.domain,
                        value: arg == null ? null : arg.value
                    };
                })}
                inputs={containerData.inputs.map(function (ii, i) {
                    // eslint-disable-next-line no-unused-vars
                    const input = executionData.inputs.find(xi => xi.name === ii.name);
                    return {
                        name: ii.name,
                        description: ii.description
                    };
                })}
                outputs={containerData.outputs.map(function (oi, i) {
                    const output = executionData.outputs.find(xi => xi.name === oi.name);
                    return {
                        name: oi.name,
                        description: oi.description,
                        representations: output.representations
                    };
                })}
            />
    );
}

function ExecutionBody(props) {
    const {
        toolId,
        toolName,
        // eslint-disable-next-line no-unused-vars
        toolSlug,
        ownerId,
        ownerUsername,
        ownerPicture,
        executionId,
        executionName,
        onNameChange,
        onOwnerChange,
        onCancel,
        onDelete,
        state,
        finished,
        arguments: args,
        inputs,
        outputs,
        reloading
    } = props;

    const { me } = useAuth();
    const isOwner = me.id === ownerId || me.superuser;

   return (
        <div className="tool-body mt-3">
            <h1>
                <Link to={`/team/${ownerUsername}`}>
                    <img alt="" src={ownerPicture} style={{ width: "28px" }} />
                </Link>
                &nbsp;
                <Link to={`/tools/${toolId}`}>
                    {toolName}
                </Link>
                {" / "}
                <Link to={`/executions/${executionId}`}>
                    {executionName}
                </Link>
            </h1>
            <ExecutionProgressBar state={state} />
            <Tabs
                defaultActivityKey="overview"
                id="execution-tabs"
                className="mb-3"
                fill
            >
                <Tab
                    eventKey="overview"
                    title={<span><BsHouseDoorFill /> Overview</span>}
                    disabled={reloading}
                >
                    <ExecutionOverview
                        id={executionId}
                        finished={finished}
                        arguments={args}
                        inputs={inputs}
                        outputs={outputs}
                    />
                </Tab>
                <Tab
                    eventKey="settings"
                    title={<span><BsGearFill /> Settings</span>}
                    disabled={reloading || !isOwner}
                >
                    <ExecutionSettings
                        name={executionName}
                        state={state}
                        onNameChange={onNameChange}
                        onOwnerChange={onOwnerChange}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        ownerId={ownerId}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}

function ExecutionProgressBar(props) {
    const { state } = props;

    let animated, striped, variant;
    switch (state) {
        case EXECUTION_STATE_STARTING:
            animated = striped = false;
            variant = "info";
            break;
        case EXECUTION_STATE_RUNNING:
            animated = striped = true;
            variant = "info";
            break;
        case EXECUTION_STATE_SUCCEEDED:
            animated = striped = false;
            variant = "success";
            break;
        case EXECUTION_STATE_FAILED:
            animated = striped = false;
            variant = "danger";
            break;
        case EXECUTION_STATE_CANCELLED:
            animated = striped = false;
            variant = "warning";
            break;
        default:
            throw new Error(state);
    }

    return (<ProgressBar className="mb-3" animated={animated} variant={variant} striped={striped} now={100} label={state} />);
}

function ExecutionOverview(props) {
    const {
        id,
        finished,
        arguments: args,
        inputs,
        outputs
    } = props;
    return (
        <>
            <ExecutionLogs
                executionId={id}
                finished={finished}
            />
            <ExecutionArgumentSection
                arguments={args}
            />
            <ExecutionInputSection
                inputs={inputs}
            />
            <ExecutionOutputSection
                outputs={outputs}
            />
        </>);
}

function ExecutionSettings(props) {
    const {
        name: executionName,
        ownerId,
        onNameChange,
        onOwnerChange,
        onCancel,
        onDelete,
        state,
        reloading
    } = props;

    const [localName, setLocalName] = useState(executionName);

    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const cancelable = state === EXECUTION_STATE_STARTING || state === EXECUTION_STATE_RUNNING;

    const { me } = useAuth();
    const permitted = me.id === ownerId;

    return (
        <>
            <h3>General</h3>
            <Form>
                <Form.Group
                    className="mb-3"
                    controlId="executionName"
                >
                    <Form.Label>Execution Name</Form.Label>
                    <Form.Control
                        type="string"
                        placeholder="Execution Name"
                        value={localName}
                        onChange={(e) => setLocalName(e.target.value)}
                        onBlur={(e) => onNameChange(e.target.value)}
                        disabled={reloading || !permitted}
                    />
                </Form.Group>
            </Form>
            <h3>Danger Zone</h3>
            <Container className="rounded-1 border border-danger p-2 mb-3">
                <Row className="mt-3">
                    <Col xs={10}>
                        <h6>Transfer Ownership</h6>
                        <p>Transfer ownership of this execution to another user. After the transfer is complete, you will not be able to transfer the execution back to yourself.</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowTransferModal(true)}
                            disabled={reloading || !permitted}
                        >
                            Transfer
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row className="mt-3">
                    <Col xs={10}>
                        <h6>Cancel Execution</h6>
                        <p>Cancel this execution, causing it to stop executing. A canceled execution cannot be restarted. No outputs will be produced.</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowCancelModal(true)}
                            disabled={reloading || !permitted || !cancelable}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={10}>
                        <h6>Delete Execution</h6>
                        <p>Delete this execution <em>permanently</em>. This cannot be undone. If the execution is still running, it will be canceled first.</p>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => setShowDeleteModal(true)}
                            disabled={reloading || !permitted}
                        >
                            Delete
                        </Button>
                    </Col>
                </Row>
            </Container>
            <ExecutionTransferModal
                show={showTransferModal}
                setShow={setShowTransferModal}
                onConfirm={onOwnerChange}
            />
            <ExecutionCancelModal
                show={showCancelModal}
                setShow={setShowCancelModal}
                onConfirm={onCancel}
            />
            <ExecutionDeleteModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                onConfirm={onDelete}
            />
        </>);
}

function ExecutionTransferModal(props) {
    const {
        onConfirm,
        show,
        setShow
    } = props;

    const [newOwner, setNewOwner] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setNewOwner(null);
        setConfirmed(false);
    }, [show]);

    const empty = newOwner === null;

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm(newOwner);
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Transfer Execution?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you transfer this execution to another user, then you will not be able to administrate it or transfer it back to yourself afterwards!
                </Alert>

                <h6>New Owner's Username</h6>
                <UserSearchBox
                    onChange={account => {
                        if (account) {
                            setNewOwner(account);
                        }
                        else {
                            setConfirmed(false);
                            setNewOwner(null);
                        }
                    }}
                />

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="executionTransferConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                            disabled={empty}
                        />
                        <Form.Label>Please check here to confirm the transfer.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Transfer
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function UserSearchBox(props) {
    const {
        onChange
    } = props;

    const { me } = useAuth();

    const [loading, setLoading] = useState(false);
    const [hits, setHits] = useState([]);

    function handleSearch(query) {
        setLoading(true);
        getAccounts(query)
            .then(response => {
                setLoading(false);
                setHits(response.data.items);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    };

    return (
        <AsyncTypeahead
            // We don't want to transfer to ourselves. So filter ourselves out.
            filterBy={hit => hit.id !== me.id}
            id="username-lookup"
            isLoading={loading}
            labelKey="username"
            minLength={1}
            onSearch={handleSearch}
            onChange={items => onChange(items[0])}
            options={hits}
            placeholder="Search username..."
            renderMenuItemChildren={option => (
                <>
                    <img
                        alt={option.username}
                        src={option.picture}
                        style={{
                            height: '24px',
                            marginRight: '10px',
                            width: '24px',
                        }}
                    />
                    <span>{option.username}</span>
                </>
            )}
        />
    );
}

function ExecutionCancelModal(props) {
    const {
        onConfirm,
        show,
        setShow
    } = props;

    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setConfirmed(false);
    }, [show]);

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm();
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Cancel Execution?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you cancel this execution, then it will stop running and it will not produce outputs. A canceled execution cannot be restarted! But you can always copy this job and run it again.
                </Alert>

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="executionCancelConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                        />
                        <Form.Label>Please check here to confirm the cancellation.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ExecutionDeleteModal(props) {
    const {
        onConfirm,
        show,
        setShow
    } = props;

    const [confirmed, setConfirmed] = useState(false);

    useEffect(function () {
        setConfirmed(false);
    }, [show]);

    function handleCancel() {
        setShow(false);
    }

    function handleConfirm() {
        setShow(false);
        onConfirm();
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Really Delete Execution?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    If you delete this execution, then it will be cancelled and then removed from your execution history along with all its outputs. This cannot be undone!
                </Alert>

                <Form>
                    <Form.Group
                        className="mt-3"
                        controlId="executionDeleteConfirm"
                    >
                        <FormCheck
                            inline
                            type="checkbox"
                            checked={confirmed}
                            onChange={e => setConfirmed(!confirmed)}
                        />
                        <Form.Label>Please check here to confirm the deletion.</Form.Label>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    className="w-100"
                    disabled={!confirmed}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ExecutionLogs(props) {
    const {
        finished,
        executionId
    } = props;

    const [first, setFirst] = useState(true);
    const [logs, setLogs] = useState([]);
    const [cursor, setCursor] = useState(null);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [timer, setTimer] = useState(null);

    useEffect(function () {
        setTimer(setInterval(function () {
            setTimestamp(Date.now());
        }, REFRESH_INTERVAL));
        return function () {
            if (timer !== null)
                clearInterval(timer);
        }
    }, []);

    useEffect(function () {
        if (first || finished === false) {
            getExecutionLogs(executionId, cursor)
                .then(function (response) {
                    // TODO Should we limit how long the logs can get?
                    setFirst(false);
                    setLogs(logs.concat(response.data.items));
                    setCursor(response.data.cursor);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [timestamp, finished]);

    return (<div className="execution-logs mb-3 p-3" style={{ overflowY: "scroll", height: "200px", backgroundColor: "black", fontFamily: "Courier, monospace", color: "white" }}>
        {logs.map(e => <div className="execution-logs-message">{e.message}</div>)}
    </div>);
}

//#region ARGUMENTS ///////////////////////////////////////////////////////////////////////////////

function ExecutionArgumentSection(props) {
    const {
        arguments: args
    } = props;

    return args.length === 0
        ? <></>
        : <>
            {
                args.map(function (ai, i) {
                    return <ExecutionArgument
                        {...ai}
                        key={"argument-" + ai.name}
                    />;
                })
            }
        </>;
}

function ExecutionArgument(props) {
    const {
        type,
        name,
        description,
        value
    } = props;

    let control;
    switch (type) {
        case 'string':
            control = <StringArgumentControl
                name={name}
                domain={props.domain}
                disabled={true}
                default={value}
            />;
            break;
        case 'int':
            control = <IntArgumentControl
                name={name}
                disabled={true}
                default={value}
                minimum={props.minimum}
                maximum={props.maximum}
            />;
            break;
        case 'float':
            control = <FloatArgumentControl
                name={name}
                disabled={true}
                default={value}
                minimum={props.minimum}
                maximum={props.maximum}
            />;
            break;
        case 'boolean':
            control = <BooleanArgumentControl
                name={name}
                disabled={true}
                default={value}
            />;
            break;
        case 'date':
            control = <DateArgumentControl
                name={name}
                disabled={true}
                default={value}
                minimum={props.minimum}
                maximum={props.maximum}
            />;
            break;
        default:
            throw new Error("unrecognized tool parameter type " + type);
    }

    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={4}>
                                <Form>
                                    <div onClick={e => e.stopPropagation()} style={{ display: "inline-block" }}>
                                        <Form.Check
                                            type="switch"
                                            inline
                                            checked={value !== null}
                                            disabled={true}
                                        />
                                    </div>
                                    <span style={{ fontSize: "150%" }}>{name}</span>
                                </Form>
                            </Col>
                            <Col xs={8} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    {control}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    );
}

function StringArgumentControl(props) {
    const {
        name,
        default: defaultValue,
        disabled,
        domain,
        onChange = function (e) { }
    } = props;

    let fallbackDefaultValue;
    switch (domain.type) {
        case "pattern":
            fallbackDefaultValue = "";
            break;
        case "enumeration":
            fallbackDefaultValue = domain.values[0];
            break;
        default:
            throw new Error("unrecognized tool string parameter domain type " + domain.type);
    }

    const [currentValue, setCurrentValue] = useState(defaultValue || fallbackDefaultValue);
    const [textValue, setTextValue] = useState(defaultValue || fallbackDefaultValue);

    let result;
    switch (domain.type) {
        case "pattern":
            result = <FormControl
                type="text"
                disabled={disabled}
                id={"parameter-" + name + "-value"}
                value={textValue}
                onChange={e => {
                    setTextValue(e.target.value);
                }}
                onBlur={e => {
                    const newValue = e.target.value;
                    if (new RegExp(domain.pattern).test(newValue)) {
                        setCurrentValue(newValue);
                        onChange(newValue);
                        if (String(newValue) !== e.target.value) {
                            // The onchange will not re-trigger this onblur
                            setTextValue(String(newValue));
                        }
                    }
                    else {
                        setTextValue(currentValue);
                    }
                }}
            />;
            break;
        case "enumeration":
            result = <FormSelect
                type="text"
                disabled={disabled}
                id={"parameter-" + name + "-value"}
                value={currentValue}
                onChange={e => { setCurrentValue(e.target.value); onChange(e.target.value); }}
            >
                {domain.values.map(value => <option value={value}>{value}</option>)}
            </FormSelect>
            break;
        default:
            throw new Error("unrecognized tool string parameter domain type " + domain.type);
    }

    return result;
}

function IntArgumentControl(props) {
    const {
        name,
        default: defaultValue,
        minimum,
        maximum,
        disabled,
        onChange = function (e) { }
    } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue !== undefined ? defaultValue : minimum);
    const [textValue, setTextValue] = useState(defaultValue !== undefined ? defaultValue : minimum);

    return <FormControl
        type="number"
        pattern="[-+]?\\d+"
        step={1}
        min={minimum}
        max={maximum}
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={textValue}
        onChange={e => {
            setTextValue(e.target.value);
        }}
        onBlur={e => {
            if (/^[-+]?[0-9]+$/.test(e.target.value)) {
                const newValue = parseInt(e.target.value);
                if (newValue >= minimum && newValue <= maximum) {
                    setCurrentValue(newValue);
                    onChange(newValue);
                    if (String(newValue) !== e.target.value) {
                        // The onchange will not re-trigger this onblur
                        setTextValue(String(newValue));
                    }
                }
                else {
                    setTextValue(currentValue);
                }
            }
            else {
                setTextValue(currentValue);
            }
        }}
    />;
}

function FloatArgumentControl(props) {
    const {
        name,
        default: defaultValue,
        minimum,
        maximum,
        disabled,
        onChange = function (e) { }
    } = props;

    const [currentValue, setCurrentValue] = useState(defaultValue !== undefined ? defaultValue : minimum);
    const [textValue, setTextValue] = useState(defaultValue !== undefined ? defaultValue : minimum);

    // TODO Should we adjust step based on range of min/max/default?
    return <FormControl
        type="number"
        pattern="[-+]?\\d+(?:[.]\\d*)?(?:[eE][-+]?\\d+)?"
        step={1}
        min={minimum}
        max={maximum}
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={textValue}
        onChange={e => {
            setTextValue(e.target.value);
        }}
        onBlur={e => {
            if (/^[-+]?\d+(?:[.]\d*)?(?:[eE][-+]?\d+)?$/.test(e.target.value)) {
                const newValue = parseFloat(e.target.value);
                if (newValue >= minimum && newValue <= maximum) {
                    setCurrentValue(newValue);
                    onChange(newValue);
                }
                else {
                    setTextValue(currentValue);
                }
            }
            else {
                setTextValue(currentValue);
            }
        }}
    />;
}

function BooleanArgumentControl(props) {
    const {
        name,
        default: defaultValue,
        disabled,
        onChange = function (e) { }
    } = props;

    const [value, setValue] = useState(defaultValue || false);

    return <FormCheck
        style={{ marginTop: "8px" }}
        type="checkbox"
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={value}
        reverse
        onChange={e => {
            const newValue = e.target.value.toLowerCase().startsWith("t");
            setValue(newValue);
            onChange(newValue);
        }}
    />;
}

function DateArgumentControl(props) {
    const {
        name,
        default: defaultValue,
        minimum,
        maximum,
        disabled,
        onChange = function (e) { }
    } = props;

    const [value, setValue] = useState(defaultValue || new Date().toISOString().substring(0, 10));

    // TODO We need to interpret the expressions for the execution
    // TODO We need to validate, like for the int type
    // TODO Convert to date type
    return <FormControl
        type="date"
        disabled={disabled}
        id={"parameter-" + name + "-value"}
        value={value}
        min={minimum}
        max={maximum}
        onChange={e => { setValue(e.target.value); onChange(e.target.value); }}
    />;
}

//#endregion

//#region INPUTS

function ExecutionInputSection(props) {
    const {
        inputs
    } = props;

    return inputs.length === 0
        ? <></>
        : <>
            {
                inputs.map(function (ii, i) {
                    return <ExecutionInput
                        {...ii}
                        key={"input-" + ii.name}
                    />;
                })
            }
        </>;
}

function ExecutionInput(props) {
    const {
        name,
        description
    } = props;

    const { executionId } = useParams();

    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={11}>
                                <span style={{ fontSize: "150%" }}>{name}</span>
                            </Col>
                            <Col xs={1} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    <Button
                                        href={"/executions/" + executionId + "/inputs/" + name}
                                        size="sm"
                                        variant="outline-secondary"
                                        onClick={function (e) {
                                            // TODO Is this the best way?
                                            document.location = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${executionId}/inputs/${name}`;
                                            e.preventDefault();
                                        }}
                                    >
                                        <BsCloudArrowDown />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

//#endregion

//#region OUTPUTS

function ExecutionOutputSection(props) {
    const {
        outputs
    } = props;

    return outputs.length === 0
        ? <></>
        : <>
            {
                outputs.map(function (oi, i) {
                    return <ExecutionOutput
                        {...oi}
                        key={"output-" + oi.name}
                    />;
                })
            }
        </>;
}

const REPRESENTATION_STATE_POPULATED = "populated";

function ExecutionOutput(props) {
    const {
        name,
        description,
        representations
    } = props;

    const { executionId } = useParams();

    // TODO Representations
    // TODO Populated
    return (
        <Accordion defaultActiveKey="0" className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Container>
                        <Row>
                            <Col xs={11}>
                                <span style={{ fontSize: "150%" }}>{name}</span>
                            </Col>
                            <Col xs={1} style={{ textAlign: "right" }}>
                                <div onClick={e => e.stopPropagation()}>
                                    <SplitButton
                                        variant="outline-secondary"
                                        size="sm"
                                        title={<BsCloudArrowDown />}
                                        disabled={representations.map(ri => ri.state !== REPRESENTATION_STATE_POPULATED).every(Boolean)}
                                        onClick={function (e) {
                                            const representation = representations.find(ri => ri.state === REPRESENTATION_STATE_POPULATED);
                                            // TODO is this the best way?
                                            document.location = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${executionId}/outputs/${name}.${representation.extension}`;
                                        }}
                                    >
                                        {representations.map(function (ri) {
                                            return <Dropdown.Item
                                                key={"output-" + name + "-representation-" + ri.extension}
                                                onClick={function (e) {
                                                    // TODO is this the best way?
                                                    document.location = `${API_BASE_URL}/workspaces/${DEFAULT_WORKSPACE}/executions/${executionId}/outputs/${name}.${ri.extension}`;
                                                }}
                                                disabled={ri.state !== REPRESENTATION_STATE_POPULATED}
                                            >
                                                {ri.extension}
                                            </Dropdown.Item>;
                                        })}
                                    </SplitButton>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

//#endregion

export default ExecutionPage;