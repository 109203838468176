import {
    useEffect,
    useState
} from "react";
import { getSecrets, getSecretByName } from "./services";

export function useSecrets(timestamp=0) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getSecrets()
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, [timestamp]);

    return [loading, data, error];
}

export function useSecretByName(secretName) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function () {
        setLoading(true);
        getSecretByName(secretName)
            .then(function (response) {
                setLoading(false);
                setData(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                setError(error);
            });
    }, []);

    return [loading, data, error];
}